<template>
  <div>
    <div v-if="props.show" class="api-display">
      <DPDialog :show="props.show" :closeLostFocus="true" :onClose="closeDialog">
        <div class="apiSelectorContainer">
          <!--HEADER DEL DIALOGO -->
          <div class="apiModalHeaderContainer">
            <h3 class="modalApiTitle">Seleccionar API</h3>
            <button class="close-button" @click="closeDialog">
              <span class="x-mark">&#10005;</span>
            </button>
          </div>
          <!-- LISTA DE APIs -->
          <div class="apiSelector">
            <div class="apiSelectorList">
              <label class="selectorHeader">APIs disponibles</label>
              <small><label v-if="isLoading">Cargando...</label></small>
              <div class="apisListContainer">
                <div v-for="api in apiList" :key="api.id" @click="selectApi(api)" class="apiSelectorItem"
                  :class="{ 'apiSelectorItemSelected': selectedApiId === api.id }">
                  <div class="apiName">{{ api.name }}</div>
                  <div class="apiDescription">{{ api.description }}</div>
                </div>
              </div>
            </div>
          </div>
          <button class="apiModalSelectBTN" @click="confirmSelection" :disabled="!selectedApiId">
            Seleccionar
          </button>
        </div>
      </DPDialog>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, toRef, toRefs, watch, watchEffect } from 'vue';
import DPDialog from '@/views/components/dialog.vue';
import Survey from '@/core/store/survey/surveyStore';
import { Sh } from '@/core/sharedService';
import { FieldType } from '@/core/store/survey/surveyDesignDtos';




const { getListApi, newItem, currentItem } = Survey();

const apiList = ref([]); 
const selectedApiId = ref(''); 
const isLoading = ref(false); 
const selectedApi = ref(null); 

const props = defineProps({
  show: Boolean as () => boolean,
})


const emit = defineEmits(['close']);




const loadApis = async () => {
  isLoading.value = true;
  const apis = await getListApi(); 
  if (Array.isArray(apis)) {
    apiList.value = apis; 
  } else {
    apiList.value = [];
    Sh.showToast("Error al obtener las APIs disponibles", 8, true);
  }
  isLoading.value = false;
};

// Cerrar el diálogo
const closeDialog = () => {
  emit('close');
  
  selectedApiId.value = '';
};

// Seleccionar una API de la lista
const selectApi = (api) => {
  selectedApiId.value = api.id;
};

// Confirmar selección y cerrar el diálogo
const confirmSelection = () => {
  const api = apiList.value.find(api => api.id === selectedApiId.value);
  if (api) {
    
    emit('close');
    
    newItem(FieldType.APIREST, api.id);
    //currentItem.value.inheritItemId = api.id;
    
  }
};

onMounted(() => {
  loadApis();
});
</script>

<style scoped lang="scss">
.apiSelectorContainer {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  max-width: 90vw;
  padding: 20px;
}

.apiModalHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modalApiTitle {
  font-size: 30px;
  font-weight: 600;
  color: var(--primary-color);
}

.apiSelector {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.selectorHeader {
  font-size: 16px;
  font-weight: 400;
  color: var(--darkgreen-text);
  margin-bottom: 10px;
}

.apisListContainer {
  background-color: var(--compositor-color);
  padding: 14px;
  min-height: 200px;
  max-height: 800px;
  border-radius: 17px;
  overflow-y: auto;
}

.apiSelectorItem {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.apiSelectorItem:hover {
  background-color: var(--secondary-color);
}

.apiSelectorItemSelected {
  background-color: var(--primary-color);
  color: white;
}

.apiName {
  font-weight: bold;
  margin-bottom: 5px;
}

.apiDescription {
  font-size: 0.9em;
}

.apiModalSelectBTN {
  align-self: flex-end;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.apiModalSelectBTN:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.api-display {
  margin-top: 10px;
}

.api-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.api-value {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
}
</style>
