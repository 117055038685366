export enum form_type{
  FORM = "FORM",
  COMPONENT = "COMPONENT"
}

export enum form_state{
  DRAFT = "DRAFT",
  CLOSED = "CLOSED",
  VALIDATED= "VALIDATED",
  ASSIGN= "ASSIGN",
}

export enum company_type{
  ROOT = "ROOT",
  CLIENT = "CLIENT",
  GROUP = "GROUP",
  ENTITY = "ENTITY",
}

export enum form_item_type{
  STATIC_TITLE = "STATIC_TITLE",
  STATIC_SUBTITLE = "STATIC_SUBTITLE",
  STATIC_TEXT = "STATIC_TEXT",
  MARC_INIT = "MARC_INIT",
  MARC_END = "MARC_END",
  FORM_TEXT = "FORM_TEXT",
  FORM_NUMBER = "FORM_NUMBER",
  FORM_DATE = "FORM_DATE",
  FORM_DATE_RANGE = "FORM_DATE_RANGE",
  FORM_COMBOBOX = "FORM_COMBOBOX",
  FORM_COMBOBOX_MULTIPLE = "FORM_COMBOBOX_MULTIPLE",
  FORM_YES_NO = "FORM_YES_NO",
  FORM_FORMULE = "FORM_FORMULE",
  FORM_FINHAVA = "FORM_FINHAVA",
}

export enum roles{
  ROOT = "ROOT",
  CLIENT = "CLIENT",
  GROUP = "GROUP",
  ENTITY = "ENTITY",
  USER = "USER",
}
export const roles_descriptions = {
  [roles.ROOT] : "Administrador",
  [roles.CLIENT] : "Cliente",
  [roles.GROUP] : "Empresa",
  [roles.ENTITY]:  "Entidad",
}

export enum entityTypes{
  CLIENT = "CLIENT",
  GROUP = "GROUP",
  ENTITY = "ENTITY",
}
export interface PropsRoute{
  role: roles;
  type: entityTypes;
  route: string;
  title: string;
  pluralTitle: string;
}
export interface RoutesProperties  {
  ROOT: PropsRoute,
  CLIENT: PropsRoute,
  GROUP: PropsRoute,
  ENTITY?: PropsRoute,
}
export const routesPoperties:RoutesProperties = {
  ROOT: {route: "clients", title: "Cliente", pluralTitle: "Clientes", role: roles.ROOT, type: entityTypes.CLIENT},
  CLIENT: {route: "groups", title: "Empresa", pluralTitle: "Empresas", role: roles.CLIENT, type: entityTypes.GROUP},
  GROUP: {route: "entities", title: "Entidad", pluralTitle: "Entidades", role: roles.GROUP, type: entityTypes.ENTITY},
}

export enum ResponseString{
  NONEWID = "E_NONEWID",
  NOUSER = "E_NOUSER",
  ERROR = "E_ERROR",
  FALSE = "E_FALSE",
  
  TRUE = "TRUE",
  DELETED = "DELETED",
  DEACTIVED = "DEACTIVED",
  
}


