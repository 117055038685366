import { default } from './surveyItem.vue';
<script setup lang="ts">
  import { Sh } from '@/core/sharedService';
import { DisplayType, FormDesignItemDto, PropertyDisplayType } from '@/core/store/survey/surveyDesignDtos';
  import { SurveyResponseItemDto } from '@/core/store/survey/surveyResponseDtos';
  import Survey from '@/core/store/survey/surveyStore';
  import { computed, CSSProperties, PropType, ref, watch } from 'vue';
  const {
      displayMode, 
      isDesign,
      } = Survey();

   const props  = defineProps({
    item: {
      type: Object as () => FormDesignItemDto,
      required: true
    },
    response: {
      type: Object as () => SurveyResponseItemDto,
      default: null,
      required: false
    },
    onChange: {
      type: Function as PropType<( value: any) => void>,
      default: null,
      required: false,
    },
  });

  const currentStyles = computed<PropertyDisplayType>(()=>{
      switch(displayMode.value){
        case DisplayType.DISPLAY: 
          return  props.item.properties.screen;
        case DisplayType.EDIT: 
          return  props.item.properties.input;
        case DisplayType.PDF: 
          return  props.item.properties.print;
      }
  });

  const cssInput = computed<CSSProperties>(() => {
    const displayProp: PropertyDisplayType=  currentStyles.value;
    return {
      backgroundColor:  displayProp.contentStyle.backgroundColor,
      borderRadius: '9999px',
      border: 'none',
      fontSize: Sh.getFontSize(displayProp.contentStyle.fontSize),
      color: displayProp.contentStyle.color,
      width: isDesign.value && displayMode.value === DisplayType.EDIT ? '344px' : '100%',
    }
  });

  const value = ref<string>(props.response ? props.response.value : '');
  
  watch(value, (newValue) => {
    if(props.response){
      if(newValue !== props.response.value){
        props.response.value = value.value.toString();
        props.onChange(value.value);
      }
    }
  });

  const isCurrentVisible = computed(() => {
    const displayProp: PropertyDisplayType=  currentStyles.value;
    return displayProp.visible || isDesign;
  });
  
</script>
<template>
  <div v-if="displayMode == DisplayType.EDIT && isCurrentVisible ">
    <input type="date" class="survey-item-input survey-input-number-buttons-none" :style="cssInput" v-model="value" :readonly="item.inheritItemId!=null && item.inheritItemId.trim().length!=0">
  </div>  
  <div v-if="displayMode == DisplayType.DISPLAY  && isCurrentVisible" >
    <input type="date" :style="cssInput" v-model="value" class="survey-item-input survey-input-number-buttons-none survey-item-input-disabled" readonly tabindex="-1">
  </div>  
  <div v-if="displayMode == DisplayType.PDF  && isCurrentVisible">
    <input type="date" :style="cssInput" v-model="value" class="survey-item-input survey-input-number-buttons-none survey-item-input-disabled" readonly tabindex="-1">
  </div>  
</template>

<style lang="scss">
  @import url(../survey.scss);
</style>

<script lang="ts">
  export default {
    name: "SurveyItemDate"
  }
</script>