
<template>
  <div class="surveyButtons-container-component">
    <span class="tittle-component">Objetos</span>
    <div class="button-component" v-for="btn in buttons" :key="btn.type"  v-on:click="select(btn)">
      <img class="button-component-icon" :src="btn.icon"/>
      <span class="button-component-text">{{ btn.label }}</span>
    </div>
    <div class="button-component" v-on:click="selectComponent()">
      <img class="button-component-icon" :src="'/assets/objectButtonsIcons/component-10.svg'"/>
      <span class="button-component-text" v-on:click="() => showSelectorComponet = true">Componentes</span>
    </div>
  </div>
  <SurveySelectorComponents :show="showSelectorComponet" :onClose="onCloseSelectorComponent"/>
  <SurveyItemAPIselector :show="showApiSelector" @close="() => showApiSelector = false"/>
</template>

<script setup lang="ts">
  
  import {ButtonComponent, surveyButtonsLeftBar as buttons, FieldType} from '@/core/store/survey/surveyDesignDtos';
  import Survey from '@/core/store/survey/surveyStore';
  import SurveySelectorComponents from './surveySelectorComponents.vue';
  import { ref } from 'vue';  
import SurveyItemAPIselector from './itemsComponents/surveyItemAPIselector.vue';
  const showSelectorComponet = ref<boolean>(false);
  const showApiSelector = ref<boolean>(false)
  const {newItem, setComponent} = Survey();
  const onCloseSelectorComponent = (value: any) => {
    showSelectorComponet.value = false;
    if(value){
      setComponent(value);
    }
  }
 
 

  const select = (btn: ButtonComponent) => {
    if(btn.type == FieldType.APIREST){
      showApiSelector.value = true;
    }else{
      newItem(btn.type);
    }
    
    
  }

  const selectComponent = ()=>{

  }
</script>


<style scoped lang="scss">
  @import url('./survey.scss');
</style>
<script lang="ts">
  export default {
    name: 'SurveyButtons',
  }
</script>