<template>
  <div :class="['header', containerClass]">
    <hr v-if="props.type === 'footer'" class="sectionSeparator" />
    <span v-if="isDesign && displayMode === DisplayType.EDIT" class="tittle-componentBody">
      {{ props.type === 'header' ? 'Encabezado' : 'Pie' }}
    </span>
    <div v-show="!isEditing" v-html="content" class="header"  v-on:click="_select()" style="display: inherit; position: relative;">
    </div>
    
    <div v-show="isEditing" v-on:click="setSelectedType(SelectedItemType.NONE)">
      <dpWysywig :html="content" :onChange="_onChange" ></dpWysywig>
    </div>
    <hr v-if="props.type === 'header'" class="sectionSeparator" />
  </div>
</template>



<script setup lang='ts'>
  import { DisplayType, SelectedItemType} from '@/core/store/survey/surveyDesignDtos';
  import Survey from '@/core/store/survey/surveyStore';
  import dpWysywig from '../dp/dpWysywig.vue';
  import { computed } from 'vue';

  const {config, displayMode,  selectedType, isDesign } = Survey();
  const {setSelectedType} = Survey();
  const _select = () => {
    if(!isDesign.value) return;
    if(props.type == 'header') setSelectedType(SelectedItemType.HEADER)
    if(props.type == 'footer') setSelectedType(SelectedItemType.FOOTER)
  }
  const props = defineProps({
    type: {
      type:  String as () =>  'header'|'footer',
      required: true,
    }
  })
  const content = computed(() => {
    if(props.type == 'header') return config.value.header;
    return config.value.footer;
  });

  const isEditing = computed(() => {
    if(displayMode.value == DisplayType.EDIT && selectedType.value ==  SelectedItemType.HEADER  && props.type == 'header' ){
      return true;
    }
    if(displayMode.value == DisplayType.EDIT && selectedType.value ==  SelectedItemType.FOOTER  && props.type == 'footer' ){
      return true;
    }
    return false;
  })

  const _onChange = (data: string) => {
    if(props.type == 'header') config.value.header = data;
    if(props.type == 'footer') config.value.footer = data;
  }

  const containerClass = computed(() => {
    
  return props.type === 'header' ? 'header-component-margin' : 'footer-component-margin'
  })

</script>



<style scoped lang="scss">
  @import url('./survey.scss');
</style>

<script lang="ts">
  export default {
  components: { dpWysywig },
    name: "SurveyHeaderFooter"
  }

</script> 