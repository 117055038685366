<script setup lang="ts">
import { DisplayType, FormDesignItemDto, FormSectionDesignDto } from '@/core/store/survey/surveyDesignDtos';
import Survey from '@/core/store/survey/surveyStore';
import { computed, onBeforeMount, ref, watch, onMounted, onUnmounted, CSSProperties } from 'vue';
import SurveyItem from './surveyItem.vue';

import bannerIcon from '/assets/bannerIcon.svg';
import trashIcon from '/assets/trash.svg';
import { Sh } from '@/core/sharedService';

const { itemsOrganized, 
        displayMode, 
        selectItem, 
        selectedSectionId, 
        selectedItemId, 
        isDesign, 
        event, 
        currentDesign, 
        setDesign 
      } = Survey();

const props = defineProps({
  section: {
    type: Object as () => FormSectionDesignDto,
    required: true
  }
});
const onClickLabel = () => {
  if (displayMode.value == DisplayType.EDIT) {
    isEditingLabel.value = true;
  }
}
const onKeyLabelEdit = (event) => {
  if (event.key === 'Enter') {

    setLabel(event.target.value);
    endEdit();
  }
}
const setLabel = (label: string) => {
  props.section.section.label = label;
  isEditingLabel.value = false;
}
const endEdit = () => {
  isEditingLabel.value = false;
}
const initDeployed = () => {
  if (selectedItemId.value == props.section.section.id) return true;
  if ([DisplayType.DISPLAY, DisplayType.PDF].includes(displayMode.value)) return true;
  return false;
};
const isEditingLabel = ref<boolean>(false)
const isDeployed = ref<boolean>(initDeployed());
const isSelected = computed(() => {
  if (selectedItemId.value == section.value.id) return true;
  return false;
})

const section = computed<FormDesignItemDto>(() => {
  return props.section.section;
})

const classComponent = computed<string>(() => {
  let clss: string[] = ["survey-base-content"];
  if (isDesign) clss.push("survey-base-content-design");
  return clss.join(" ");
})

watch(itemsOrganized, (newValue, oldValue) => {
  let idSectionChangeItem: string = null
  let x = 0;
  for (const i of <FormSectionDesignDto[]>newValue) {
    if ((<FormSectionDesignDto[]>oldValue)[x] && (<FormSectionDesignDto[]>oldValue)[x].items.length !== i.items.length) {
      idSectionChangeItem = i.section.id;
    }
    x++;
  }
  if (idSectionChangeItem == section.value.id) {
    isDeployed.value = true;
  }
});

onMounted(() => {
  event.on("deploy", deploy);
});
onUnmounted(() => {
  event.off("deploy", deploy);
})
onBeforeMount(() => {
  if (selectedSectionId.value == props.section.section.id) {
    isDeployed.value = true;
  }
});
const toggleSection = () => {
  if (displayMode.value == DisplayType.PDF && !isDesign.value) return;
  isDeployed.value = !isDeployed.value;
  selectItem(section.value.id);
}
const deploy = (dep: boolean = true) => {
  isDeployed.value = dep;
}

const sectionStyle = computed<CSSProperties>(() => {
  const sect = section.value;
  const currentStyle = displayMode.value == DisplayType.DISPLAY ? sect.properties.screen.labelStyle : (displayMode.value == DisplayType.EDIT ? sect.properties.input.labelStyle : sect.properties.print.labelStyle);
  let css: CSSProperties = {
    width: currentStyle.width ? `${currentStyle.width}%` : "100%",
    minHeight: "40px",
    fontSize: Sh.getFontSize(currentStyle.fontSize, 16),
    color: currentStyle.color,
    backgroundColor: currentStyle.backgroundColor,
    textAlign: currentStyle.align,
    fontWeight: currentStyle.bold ? 'bold' : 'normal',
    paddingLeft: "20px",
    paddingRight: "20px",
    // marginTop: "10px",
  };
  // if(displayMode.value!== DisplayType.PDF){
  css["borderRadius"] = "5px";
  css["z-index:"] = "999 !important";
  // }
  return css;
});

// DELETE SECTION
const deleteSection = async (event: Event) => {
  event.stopPropagation(); 

  const result = await Sh.showGenericDialog({
    title: "Confirmar eliminación",
    message: "¿Estás seguro de que quieres eliminar esta sección?"
  });

  if (result) {
    const updatedItems = currentDesign.value.items.filter(item => item.id !== props.section.section.id);
    const finalItems = updatedItems.filter(item => item.parentItemId !== props.section.section.id);

    setDesign({
      ...currentDesign.value,
      items: finalItems
    });

    if (selectedSectionId.value === props.section.section.id) {
      selectItem('');
    }

    console.log('Sección eliminada:', props.section.section.id);
  }
}


const isSectionEmpty = computed(() => props.section.items.length === 0);



</script>

<template>
  <div v-on:click="toggleSection" :style="sectionStyle" :class="{ 'is-flipped': isDeployed }">

    <div class="survey-section">
      <span v-if="!isEditingLabel" class="survey-section-title" v-on:click="onClickLabel">{{ section.label }}</span>
      <input v-if="isEditingLabel" type="text" v-model="props.section.section.label"
        class="survey-item-label-input survey-section-title" :style="sectionStyle" v-on:keyup="onKeyLabelEdit"
        v-on:blur="endEdit">
      <div class="sectionBannerBTNContainer">
        <button v-if="displayMode == DisplayType.EDIT && isSectionEmpty" class="deleteSeccion-BTN" @click="deleteSection">
          <img class="survey-trashIcon" :src="trashIcon" alt="Icono de eliminar">
        </button>
        <img v-if="displayMode !== DisplayType.PDF" class="survey-bannerIcon" :src="bannerIcon"
          alt="Icono de survey-banner">
      </div>


    </div>
  </div>
  <BCollapse v-model="isDeployed">
    <SurveyItem v-for="item in props.section.items" v-on:click="selectItem(item.id)" :item="item" :response="item.response ?? null"/>
  </BCollapse>
</template>

<script lang="ts">

export default {
  name: "SurveyItemSection",
}
</script>

<style lang="scss">
  @import url(../survey.scss);



</style>