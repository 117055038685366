<script setup lang="ts">
import { Sh } from '@/core/sharedService';
import { DisplayType, FieldType, FormDesignItemDto, ItemMultiDrawarer, PropertyDisplayType } from '@/core/store/survey/surveyDesignDtos';
import { SurveyResponseItemDto } from '@/core/store/survey/surveyResponseDtos';
import Survey from '@/core/store/survey/surveyStore';
import { computed, CSSProperties, PropType, ref, watch } from 'vue';

const { displayMode, isDesign } = Survey();

const props = defineProps({
  item: {
    type: Object as PropType<FormDesignItemDto>,
    required: true
  },
  response: {
    type: Object as PropType<SurveyResponseItemDto>,
    default: null,
    required: false
  },
  multiple: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  onChange: {
    type: Function as PropType<(value: any) => void>,
    default: null,
    required: false,
  },
});

const items = computed<ItemMultiDrawarer[]>(() => {
  return props.item.items ?? [];
});

const currentStyles = computed<PropertyDisplayType>(() => {
  switch (displayMode.value) {
    case DisplayType.DISPLAY:
      return props.item.properties.screen;
    case DisplayType.EDIT:
      return props.item.properties.input;
    case DisplayType.PDF:
      return props.item.properties.print;
  }
});

const cssInput = computed<CSSProperties>(() => {
  const displayProp: PropertyDisplayType = currentStyles.value;
  return {
    backgroundColor: displayProp.contentStyle.backgroundColor,
    fontSize: Sh.getFontSize(displayProp.contentStyle.fontSize),
    color: displayProp.contentStyle.color,
    border: 'none',
    borderRadius: '9px',
    padding: '4px 10px',
    width: isDesign.value && displayMode.value === DisplayType.EDIT ? '344px' : '100%',
  }
});

const value = ref<number[]>(props.response && props.response.value ? props.response.value : []);

watch(value, (newValue) => {
  if (props.response) {
    if (JSON.stringify(newValue) !== JSON.stringify(props.response.value)) {
      props.response.value = value.value;
      props.onChange(value.value);
    }
  }
});

const isCurrentVisible = computed(() => {
  const displayProp: PropertyDisplayType = currentStyles.value;
  return displayProp.visible || isDesign;
});

const handleChange = (event: Event | number) => {
  if (props.multiple) {
    const index = event as number;
    const valueIndex = value.value.indexOf(index);
    if (valueIndex === -1) {
      value.value.push(index);
    } else {
      value.value.splice(valueIndex, 1);
    }
  } else {
    const target = event as Event;
    const selectElement = target.target as HTMLSelectElement;
    value.value = [parseInt(selectElement.value)];
  }
};

const itemsDisplay = computed<{ label: string; selected: boolean }[]>(() => {
  const its: { label: string; selected: boolean }[] = [];
  if (isDesign.value) {
    if (props.item.type == FieldType.MULTYDROPDOWN) {
      let sw = true;
      for (const v of props.item.items) {
        its.push({ label: v.label, selected: sw });
        sw = !sw;
      }
    } else {
      its.push({ label: (props.item.items && props.item.items.length ? props.item.items[0].label : "Item seleccionado"), selected: true })
    }
  } else {
    const curVal = value.value;
    for (const [index, v] of props.item.items.entries()) {
      its.push({ label: v.label, selected: curVal.includes(index) });
    }
  }
  return its;
});
</script>

<template>
  <div class="survey-item-dropdown-selectContainer" v-if="displayMode == DisplayType.EDIT && isCurrentVisible">
    <select v-if="!props.multiple" :style="cssInput" class="survey-item-dropdown-select customSelect" @change="handleChange"
      :disabled="item.inheritItemId != null && item.inheritItemId.trim().length != 0">
      <option value="null" :selected="value.length == 0">Seleccione una opción...</option>
      <option class="optionItemSelect" v-for="(i, index) in items" :key="index" :value="index"
        :selected="value.includes(index)">
        {{ i.label }}
      </option>
    </select>
    <div v-else :style="cssInput" class="survey-item-dropdown-select multiple">
      <div v-if="value.length == 0" class="placeholder">Seleccione una opción...</div>
      <div v-for="(i, index) in items" :key="index" class="option" :class="{ 'selected': value.includes(index) }"
        @click="handleChange(index)" :style="{ pointerEvents: item.inheritItemId != null && item.inheritItemId.trim().length != 0 ? 'none' : 'auto' }">
        {{ i.label }}
      </div>
    </div>
  </div>
  <div v-if="[DisplayType.PDF, DisplayType.DISPLAY].includes(displayMode) && isCurrentVisible"
    class="survey-item-dropdown-items">
    <div v-for="(i, index) in itemsDisplay" :key="index" class="survey-item-dropdown-item">
      <template v-if="i.selected || !currentStyles.itemOnlySelected">
        <div class="survey-item-dropdown-item-check">
          <img v-if="i.selected && props.item.type == FieldType.MULTYDROPDOWN && !currentStyles.itemOnlySelected"
            src="/assets/check.svg" />
        </div>
        <div class="survey-item-dropdown-item-label">
          {{ i.label }}
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
@import url(../survey.scss);

.customSelect{
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-image: url('/assets/bannerIcon.svg') !important;
  background-size: 15px 15px !important;
  background-repeat: no-repeat !important;
  background-position: right 15px center !important;
  padding-right: 30px !important;
}

.survey-item-dropdown-select {
  &.multiple {
    position: relative;
    cursor: pointer;
    user-select: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    min-height: 100px;
    max-height: 200px;
    overflow-y: auto;

    .placeholder {
      color: var(--composer-text);
    }

    .option {
      padding: 5px;
      &:hover {
        background-color: var(--secondary-color);
        border-radius: 999px;
        padding-left: 15px;
      }
      &.selected {
        background-color: var(--primary-color);
        color: white;
        padding-left: 15px;
        font-weight: bold;
        border-radius: 999px;
        margin: 5px 0px 5px 0px;
      }
    }
  }
}
</style>

<script lang="ts">
export default {
  name: "SurveyItemDropDown"
}
</script>