import { _UnwrapAll, storeToRefs } from "pinia";
import { DisplayType, FieldType, FormDesignDto, FormDesignItemDto, FormSectionDesignDto, defaultItem, ItemsType } from "./surveyDesignDtos";
import { ApiInterface, Sh, TagCalculateItem, TagObject } from "@/core/sharedService";
import { apiRoutes } from "@/core/apiRouters";
import { Ref, ref } from "vue";
import mitt from 'mitt';
import { GlobalState } from "../global/gobalState";
import { entityTypes, form_type } from "@/shared/globaEnums";
import { LocalStorageService } from "@/core/localStorage.service";
import { SurveyResponseCompleteDto, SurveyResponseDto, SurveyResponseValueDateRange, SurveyResponseValueFile } from './surveyResponseDtos';
import { SurveyBaseStore } from "./surveyBaseStore";
import { ResultTest, SurveyApiDto, SurveyApiListItemDto, SurveyApiParms } from "./surveyApiDtos";
import { v4 as uuidv4 } from 'uuid';
import { JSONPath } from 'jsonpath-plus';
import { Form_status } from "../forms/dtos";

type Events = {
  deploy: boolean;
};

class SurveyStore extends SurveyBaseStore { // SurveyBaseStore es la base donde apunta al store.
  originSelectorShow: Ref<boolean> = ref(false);
  originSelectorItem: Ref<FormDesignItemDto> = ref(null);
  event = mitt<Events>();
  formulaCreatorShow: Ref<boolean> = ref(false);
  formulaCreatorItem: Ref<FormDesignItemDto> = ref(null);
  isLoading = ref<boolean>(false);

  constructor() {
    super();
  }

  moveTo = (item: FormDesignItemDto, order: number) =>  {
    const itemsGroup = this.itemsOrganized.value.find(i => i.items.find(it => it.id == item.id));
    // console.log('Items Group:', itemsGroup);
    
    if (!itemsGroup) {
        console.log('No se encontró el grupo de ítems.');
        return;
    }
    
    const itemTarget = itemsGroup.items.find(i => i.order == order);
    console.log('Item Target:', itemTarget);
    
    if (!itemTarget) {
        console.log('No se encontró un ítem con el orden especificado:', order);
        return;
    }

    itemTarget.order = item.order;
    item.order = order;

    // console.log('Orden actualizado:', { itemTarget, item });
}

  deployAllSections = (deploy: boolean) => {
    this.event.emit("deploy", deploy);
  }
  deleteItem = (item: FormDesignItemDto): boolean => {
    this.removeItem(item);
    return true;
  }

  getGroupOfItem = (item: FormDesignItemDto | string): FormSectionDesignDto => {
    return this.itemsOrganized.value.find(i => i.items.find(it => it.id == (typeof item == 'string' ? item : item.id)));
  }

  lasItemGroup = (itemGroup: FormDesignItemDto | FormSectionDesignDto): number => {
    // console.log('Item Group:', itemGroup);
    
    let i: FormSectionDesignDto = itemGroup instanceof FormDesignItemDto ? this.getGroupOfItem(itemGroup) : itemGroup;
    // console.log('Grupo de ítems:', i);
    
    if (!i || !i.items) {
        // console.log('El grupo de ítems o sus ítems no están definidos.');
        return 0;
    }
    
    if (i.items.length) {
        return i.items[i.items.length - 1].order;
    }
    
    return 0;
  }

  addDesign = () => {
    this.newDesign();
    this.newItem(FieldType.SECTION);
  }
  newItem = (type: FieldType, inheritsId?: string): FormDesignItemDto => {
    let item: FormDesignItemDto;
    try {
      if ([FieldType.SECTION.toString(), FieldType.NEWPAGE.toString()].includes(type)) {
        const order: number = this.itemsOrganized.value.length ?
          this.itemsOrganized.value[this.itemsOrganized.value.length - 1].order + 1 : 1;
        item = defaultItem(type, this.currentDesign.value, order);
    
      } else if (this.currentSection.value != null) {
        item = defaultItem(type, this.currentDesign.value, this.currentSection.value, this.getNewTag());
        if(inheritsId && type == FieldType.APIREST){
          this.getApi(inheritsId).then(api => {
            if(api!=false){
              item.content = JSON.stringify(this.getVarsApi(api as SurveyApiDto));
              this.selectItem(item.id);
            }
          })
        }
      } else {
        throw "No hay ninguna sección seleccionada.";
      }
    } catch (e) {
      throw e;
    }
    const last = this.currentDesign.value.items.filter(i => i.type == type);
    if(last.length){
        item.properties = {...last[last.length-1].properties};
    }
    this.addItem(item);
    
    return item;
  }
  getNewTag = () => {
    let lastCounter = this.currentDesign.value.cab.fieldCounter ?? 0;
    lastCounter++;
    this.bindCab("fieldCounter", lastCounter);
    return "v-"+lastCounter.toString();
  };
  loadDesign = async (formId: string) => {
    try {
      this.loading.value = true;
      this.errors.value = [];
      const form: FormDesignDto = await Sh.api<FormDesignDto>({
        method: 'GET',
        route: apiRoutes.surveyGetDesign,
        params: [formId],
      });
      this.setDesign(form);
    } catch (e) {
      this.errors.value = ["Error"];
    } finally {
      this.loading.value = false;
    }
  }
  copyDesign = (from: DisplayType, to: DisplayType) => {
    const fromDesign = from == DisplayType.EDIT ? this.currentItem.value.properties.input : (from == DisplayType.DISPLAY ? this.currentItem.value.properties.screen : this.currentItem.value.properties.print);
    switch(to){
      case DisplayType.EDIT:
        this.currentItem.value.properties.input = {...fromDesign};
        break;
      case DisplayType.DISPLAY:
        this.currentItem.value.properties.screen = {...fromDesign};
        break;
      case DisplayType.PDF:
        this.currentItem.value.properties.print = {...fromDesign};
        break;        
    }
    this.updateItem(this.currentItem.value);
  }
  saveCab = async () => {
    try {
      if(!this.currentDesign.value.cab.clientId) {
        const {
          currentContext
        } = storeToRefs(GlobalState());
        const currentEntity = currentContext.value.entity;
        if(currentEntity.clientId) {
          this.currentDesign.value.cab.clientId = currentEntity.clientId;
        }else if(currentEntity.entityType == entityTypes.CLIENT ){
          this.currentDesign.value.cab.clientId  =  currentEntity.id;
        }else{
          alert("cliente no asignado");
          return;
        }

      }
      const errors = this.validCab();
      if(errors.length){

        let messagesErrors = errors.map(error => error).join('<br/>');

        Sh.showGenericDialog({
          title: "Error al crear el formulario",
          message: messagesErrors,
          confirmOnly: true,
          confirmText: "Cerrar"
        })
        // alert("Error al crear el formulario \n" + errors.join("\n"));
        return;
      }
      this.loading.value = true;
      this.errors.value = [];
      const form: FormDesignDto = await Sh.api<FormDesignDto>({
        method: 'POST',
        route: apiRoutes.surveyDesignUpdate,
        data: this.currentDesign.value,
      });
      this.setDesign(form);
      LocalStorageService.setValue("lastDesign", {id: form.cab.id, isNew : false});
      Sh.showToast("Formulario guardado correctamente", 8, false);
    } catch (e) {
      Sh.showToast("Error al guardar el formuario", 8, true);
      this.errors.value = ["Error"];
    } finally {
      this.loading.value = false;
    }
  }
  validCab = ():string[] =>{
    const cab = this.currentDesign.value.cab;
    const errors: string[] = [];
    if(!cab.name) errors.push("El nombre del formulario es obligatorio." + "\n");
    if(!cab.initDate || !cab.initDate.trim().length) errors.push("La fecha de inicio formulario es obligatoria." + "\n");
    if(!cab.endDate || !cab.endDate.trim().length) errors.push("La fecha de fin formulario es obligatoria." + "\n");
    this.validItems(errors);
    return errors; 
  }
  validItems = (errors: string[]) =>{
    

    return errors; 
  }

  bindCab = (field: string, value: any) => {
    
    this.currentDesign.value =  {...this.currentDesign.value, cab: {...this.currentDesign.value.cab, [field]: value}}
    if(field == "formType" && value == form_type.COMPONENT){
      this.currentDesign.value.cab.endDate = '01-01-1900';
      this.currentDesign.value.cab.initDate = '01-01-1900';
    }
    this.setDesign(this.currentDesign.value);
  }
  targetToProperty = (target: DisplayType):string => {
    return {[DisplayType.EDIT]: 'input', [DisplayType.DISPLAY]: 'screen', [DisplayType.PDF]: 'print'}[target];
  }
  bindItem =(type: 'item'|'style'|'display', target: DisplayType, labelContent: 'contentStyle'|'labelStyle' , field: string, value: any) => {
    switch(type){
      case 'style':
        this.currentItem.value.properties[this.targetToProperty(target)][labelContent][field] = value;
        break;
      case 'item':
        this.currentItem.value[field] = value;
        break;
      case 'display':
        this.currentItem.value.properties[this.targetToProperty(target)][field] = value;
        break;
    }
  } 
  getValueItem = (type: 'item'|'style'|'display', target: DisplayType, labelContent: 'contentStyle'|'labelStyle' , field: string) => {
    switch(type){
      case 'style':
        return this.currentItem.value.properties[this.targetToProperty(target)][labelContent][field];
        break;
      case 'item':
        return this.currentItem.value[field] ;
        break;
      case 'display':
        return this.currentItem.value.properties[this.targetToProperty(target)][field];
        break;
    }
  }
 

  defaltItemValue = (item: FormDesignItemDto) => {
    switch(item.type){
      case FieldType.DATERANGE: return new SurveyResponseValueDateRange();
      case FieldType.DROPDOWN: return [];
      case FieldType.MULTYDROPDOWN: return [];
      case FieldType.FILE: return new SurveyResponseValueFile();
      case FieldType.FORMREF:
        if(item.itemsType == ItemsType.DATE) return "";
        if(item.itemsType == ItemsType.NUMBER) return 0;
        if(item.itemsType == ItemsType.TEXT) return "";
        break;
      case FieldType.FORMULE: return 0;
      case FieldType.GRID: return [];
      case FieldType.NUMBER: return 0;
      case FieldType.YESNO: return Boolean;
      default: return "";
    }
  }

  //NUEVO MÉTODO PARA MOSTRAR EL DIÁLOGO DE FÓRMULAS
   showFormulaCreator = (item: FormDesignItemDto) => {
    this.formulaCreatorItem.value = item;
    this.formulaCreatorShow.value = true;
  }

  showOriginSelector = (item: FormDesignItemDto) => {
    this.originSelectorItem.value = item;
    this.originSelectorShow.value = true;
  }
  getForms = async (components: boolean = false) => {
    try{
      this.isLoading.value = true;
      const list = await Sh.api<{id: string, name: string}[]>({
        method: 'GET',
        route: !components ?  apiRoutes.surveyDesingListSimple : apiRoutes.surveyDesingListSimpleCompontents
      });
      this.isLoading.value = false;
      return list;
    }catch(e){
      this.isLoading.value = false;
      console.log("Error recuperando formularios");
      return [];
    }
    
  }
  getItems = async (formId: string) => {
    try{
      const list = await Sh.api<{id: string, name: string}[]>({
        method: 'GET',
        route: apiRoutes.surveyDesingListItemSimple,
        params: [formId]
      });
      return list;
    }catch(e){
      console.log("Error recuperando formularios");
      return [];
    }
  }

  setComponent = async (formId: string ) => {
    try {
      this.loading.value = true;
      this.errors.value = [];
      const form: FormDesignDto = await Sh.api<FormDesignDto>({
        method: 'GET',
        route: apiRoutes.surveyGetDesign,
        params: [formId],
      });
      const oldDatas: {oldId: string, oldTag: string, newId: string, newTag: string}[] = [];
      const items: FormDesignItemDto[] = form.items;
      const formulas: FormDesignItemDto[] = form.items.filter(i => i.type == FieldType.FORMULE);
      const sections: FormDesignItemDto[] = form.items.filter(i => i.type == FieldType.SECTION);
      
      items.map(i => {
        const newId = uuidv4();
        const newTag = this.getNewTag();
        oldDatas.push({oldId: i.id, oldTag: i.tag, newId: newId, newTag: newTag});
        i.id = newId;
        i.tag = newTag;
        i.isNew = true;
        if(i.items.length){
          
          for(const it of i.items){
            const newTagIt = this.getNewTag();
            oldDatas.push({oldId: "", oldTag: it.tag, newId: "", newTag: newTagIt});
            it.tag = newTagIt;
          }
        }
      })
      if(formulas.length){
        formulas.map(f => {
          oldDatas.map(o => {
            const tOld = `{${o.oldTag}}`;
            const tOldValue = `{${o.oldTag}.value}`;
            const tNew = `{${o.newTag}}`;
            const tNewValue = `{${o.newTag}.value}`;
            
            f.content = f.content.replace(new RegExp(tOld, 'g'), tNew);
            f.content = f.content.replace(new RegExp(tOldValue, 'g'), tNewValue);
          })
        });
      }
      
      sections.map(s => {
        const oldSectionId = oldDatas.find(o => o.newId === s.id)["oldId"];
        items.filter(i => i.parentItemId == oldSectionId).map(i2 => i2.parentItemId = s.id);
        s.order =  this.itemsOrganized.value.length ? this.itemsOrganized.value[this.itemsOrganized.value.length - 1].order + 1 : 1
      });
      this.currentDesign.value.items = [...this.currentDesign.value.items, ...items];

     // this.setDesign(form);
    } catch (e) {
      this.errors.value = ["Error"];
    } finally {
      this.loading.value = false;
    }
  }
  asignItemNew = async (itemId: string, item: FormDesignItemDto) => {
    try{
      const itemNew = await Sh.api<FormDesignItemDto>({
        method: 'GET',
        route: apiRoutes.surveyDesingItem,
        params: [itemId]
      });
      itemNew.id = item.id;
      itemNew.inheritItemId = itemId;
      itemNew.formId = item.formId;
      itemNew.isNew = true;
      itemNew.tag = this.getNewTag();
      this.asignItem(item, itemNew);
    }catch(e){
      console.log("error asignando item", e);
      return [];
    }
  }
  canRemoveDesign = async (id: string): Promise<boolean> => {
    try{
      this.isLoading.value = true;
      const result = await Sh.api<boolean>({
        method: "GET", 
        route: apiRoutes.surveyCanDelete, 
        params: [id]
      });
      console.log("se pue o no se pue borrá?", result);
      return result;
    }catch(e){
      return false;
    }finally{
      this.isLoading.value = false;
    }
  }
  removeDesign = async (id: string): Promise<boolean> => {
    try{
      this.isLoading.value = true;
      const result = await Sh.api<boolean>({
        method: "DELETE", 
        route: apiRoutes.surveyDelete, 
        params: [id]
      });
      
      return result;
    }catch(e){
      return false;
    }finally{
      this.isLoading.value = false;
    }
  }



  //#region Response
    getResponseItemValue = (item: FormDesignItemDto ) => {
      const curResponse = this.currentResponse.value;
      if(!curResponse || !curResponse.items || !curResponse.items.length) return this.defaltItemValue(item);
      const curResponseItem = curResponse.items.find(i => i.formsDesignItemId === item.id);
      if(!curResponseItem){
        const {currentContext} =  GlobalState();
        const value = this.defaltItemValue(item);
        this.addResponseItem(item, this.defaltItemValue(item), currentContext.user.id)
        return value;
      }
      return curResponseItem.value;
    }
    getResponse = async (formDesignId: string, entityId: string) => {
      try {
        this.loading.value = true;
        const {currentContext} =  GlobalState();
        const result = await Sh.api<SurveyResponseCompleteDto>({
          method: 'GET',
          route: apiRoutes.surveyGetResponse,
          params: [entityId, formDesignId]
        });
        this.setDesign(result.design);
        this.setResponse(result.response?? null, entityId, currentContext.user.id);
        const formRefs = result.design.items.filter(i => i.type== FieldType.FORMREF && !Sh.isNullOrEmptyOrZero(i.inheritItemId));
        const apis = result.design.items.filter(i => i.type== FieldType.APIREST);
        if(formRefs.length){
          const resultIn = await Sh.api<SurveyResponseCompleteDto>({
            method: 'POST',
            route: apiRoutes.surveyInherits,
            data: formRefs,
            params: [entityId]
          });
          if(Object.keys(resultIn).length !=0){
            const designInherits = result.design.items.filter(i => i.type== FieldType.APIREST && !Sh.isNullOrEmptyOrZero(i.inheritItemId));
            for(const i of designInherits){
              if(resultIn[i.inheritItemId]){
                i.response.value = resultIn[i.inheritItemId];
                this.formatValue(i.response, i );
              }
            }
          }
          this.calculateFormulas();
          console.log("resultado de inherits", resultIn);
        }
        if(apis.length){
          const {
            currentContext
          } = storeToRefs(GlobalState());
          for(const a of apis){
            if(Sh.isNullOrEmptyOrZero(a.content)) continue;
            const apiParams: SurveyApiParms = JSON.parse(a.content);
            for(const v of apiParams.globals){
              switch(v.var){
                case "id-finhava":
                  v.value = currentContext.value.entity.finhavaId;
                  break;
                case "cif-entidad":
                  v.value = currentContext.value.entity.identity.vatNumber;
                  break;
              }
            }
            try{
                const res = await  this.getDataApi(apiParams);
                const response =  this.currentResponse.value.items.find(i => i.formsDesignItemId == a.id);
                if(response){
                  response.value = res.value ;
                  this.formatValue(response, a );
                }
                
            }catch(e){
              console.log("error finhava", e)
            }
             // const value = this.getDataApi()
          }
        }

      } catch (error) {
        throw error;
      }finally{
        this.loading.value = false;
      }
    }
    calculateFormulas = async () => {
      const formulas = this.currentDesign.value.items.filter(i=> i.type == FieldType.FORMULE);
      if(!formulas || formulas.length == 0 ) return;
      const formulasToAply: {id: string, tag: string, content: string,  tags: TagObject[]}[] = [];
      for(const i of formulas){
        if(Sh.isNullOrEmptyOrZero(i.content)) continue;
        const tag: {id: string, tag: string, content: string, tags: TagObject[]} = {id: i.id, tag: i.tag, content: i.content, tags: Sh.extractTags(i.content)};
        formulasToAply.push(tag);
      }
      if(!formulasToAply.length) return;
      const topo: TagCalculateItem[] = Sh.topologicalSortFormulas(formulasToAply.map(i=> <TagCalculateItem>{tag: i.tag, tags: i.tags}));
      for(const f of  formulasToAply){
        let formula: string = f.content;
        for(const t of f.tags){
          const response = this.currentDesign.value.items.find(i=> i.tag.trim() == t.tag.trim());
          if(!response){
            const multi = this.currentDesign.value.items.filter(i=> i.type == FieldType.GRID).find(i => i.items.find(is=> is.tag == t.tag));
            if(multi){
              formula = formula.replace( (t.value ? `{${t.tag}.value}` : `{${t.tag}}`) , this.getValueOfItemDrawer(multi, t.tag, t.value).toString() );  
              continue;
            }
            formula = formula.replace( (t.value ? `{${t.tag}.value}` : `{${t.tag}}`) , "0");
            continue;
          }
          formula = formula.replace((t.value ? `{${t.tag}.value}` : `{${t.tag}}`), this.getValueOfDesignItem(response, t.value).toString());
        }
        const curItem = this.currentDesign.value.items.find(i => i.id == f.id);
        const result = Sh.evaluateForm(formula);
        if(typeof result == 'number' || typeof result == 'bigint'){
          this.changeReponseValue(curItem, result);
          
        }else{
          this.changeReponseValue(curItem, 0);
        }
        
        
      }

    }
    getValueOfItemDrawer = (item: FormDesignItemDto, tag: string, isValue: boolean = true): number => {
      if(!item.response) return 0;
      const dr = item.items.find(i => i.tag === tag);
      switch(item.itemsType){
        case ItemsType.DATE:
        case ItemsType.TEXT:
          try{
            if(dr) return  parseFloat(dr.value);
            return 0;
          }catch(e){
            return 0;
          }
        case ItemsType.NUMBER:                  
          if(!isValue) return parseFloat(dr.value);
         const idx = item.items.findIndex(i => i.tag == tag);
         if(idx == -1) return 0;
         if(!item.response.value[idx]) return 0;
         return parseFloat(item.response.value[idx]); 
      }
    }
    getValueOfDesignItem = (item: FormDesignItemDto, isValue: boolean = true): number => {
      if(!item.response) return 0;
      if(!item.response.value) return 0;
      const value: any = item.response.value;

      switch(item.type){
        case FieldType.TEXT:
        case FieldType.DATE:
          if(Sh.isNullOrEmptyOrZero(value)) return item.weightEmpty ?? 0;
          return item.weight ?? 0;
        case FieldType.NUMBER:
          if(isValue)
            return value;
          return Sh.isNullOrEmptyOrZero(value) ? item.weightEmpty ?? 0 : item.weight ?? 0;
        case FieldType.DATERANGE:
          
          const init = value.init ? new Date(Sh.toShortDate(value.init, true)).getTime() : 0;
          const end = value.end ? new Date(Sh.toShortDate(value.end, true)).getTime() : 0;
          if(!init || !end) return item.weightEmpty ?? 0;
          if(!isValue) return item.weight ?? 0;
          return ((end - init) / 1000) / 86400;
        case FieldType.DROPDOWN:
        case FieldType.MULTYDROPDOWN:
          
          const val = value.reduce((acc, index) => acc +  parseFloat(item.items[index].value), 0);
          if(!val && item.weightEmpty) return item.weightEmpty;

          if(!isValue)
              return  Sh.isNullOrEmptyOrZero(val) ? item.weightEmpty ?? 0 : item.weight ?? 0;
          return val;
        case FieldType.GRID:
          if(item.itemsType == ItemsType.NUMBER){
            const val = value.reduce((acc, obj) => acc + (obj ? parseFloat(obj): 0) , 0);
            if(!val && item.weightEmpty) return item.weightEmpty;
            if(!val) return 0;
            if(!isValue)
              return  Sh.isNullOrEmptyOrZero(val) ? item.weightEmpty ?? 0 : item.weight ?? 0;
            return val;
          }else{
            const val = value.reduce((acc, obj, idx) => acc +  parseFloat(item.items[idx].value), 0);
            if(!val && item.weightEmpty) return item.weightEmpty;
            if(!val) return 0;
            if(!isValue)
              return  Sh.isNullOrEmptyOrZero(val) ? item.weightEmpty ?? 0 : item.weight ?? 0;
            return val;
          }
        case FieldType.FILE:
          return  Sh.isNullOrEmptyOrZero(value.id) ? item.weightEmpty ?? 0 : item.weight ?? 0;
          case FieldType.FORMULE:
          case FieldType.FORMREF:
          case FieldType.APIREST:
              return  Sh.isNullOrEmptyOrZero(value) ? parseFloat(value) : 0;
      }
      return Sh.isNullOrEmptyOrZero(value) ?  item.weightEmpty ?? 0 : item.weight ?? 0;
    }
    saveResponse = async () =>  {
      try{
        this.asignDesignItemsToResponse();
        this.loading.value = true;
        const st = this.responseStatus.value;
        this.currentResponse.value.questionsRequireds = st.requireds;
        this.currentResponse.value.questionsEnded = st.response;
        this.currentResponse.value.result = st.result;

        // Todo resultado del formulario.
        const result = await Sh.api<SurveyResponseDto>({
          method: 'POST',
          route: apiRoutes.surveySaveResponse,
          data: SurveyResponseDto.toSend(this.currentResponse.value)
        });
        this.setResponse(result);
      }catch(e){
        throw e;
      }
    }
    asignDesignItemsToResponse = () => {
      for(const i of this.currentResponse.value.items){
        const rDesign = this.currentDesign.value.items.find(id => id.id === i.formsDesignItemId );
        if(rDesign){
          i.value = rDesign.response.value;
        }
      }
    }

    onChangeResponseItem = () => {
      this.calculateFormulas();
      console.log("ha cambiado un item");
    }
    changeState = async (newState: Form_status) => {
      this.loading.value = true;
      const result = await Sh.api<boolean>({
        method: 'POST',
        route: apiRoutes.surveyChangeStatus,
        params: [this.currentResponse.value.id, newState]
      });
      if(result == true){
        this.changeStatus(newState);
        
      }
    }


  //#endregion Response
  //#region Api
    getListApi = async  (): Promise<boolean | SurveyApiListItemDto[]> => {
        
      try {
        this.loading.value = true;
        const result = await Sh.api<SurveyApiListItemDto[]>({
          method: 'GET',
          route: apiRoutes.surveyApiList,
        });
        this.loading.value = false;
        return result;  
      } catch (error) {
        this.loading.value = false;
        return false;
      }
    }
    getApi = async  (apiId: string): Promise<boolean | SurveyApiDto> => {
        
      try {
        this.loading.value = true;
        const result = await Sh.api<SurveyApiDto>({
          method: 'GET',
          route: apiRoutes.surveyApiGet,
          params: [apiId]
        });
        this.loading.value = false;
        return result;  
      } catch (error) {
        this.loading.value = false;
        return false;
      }
    }
    updateApi = async (api: SurveyApiDto): Promise<boolean | SurveyApiDto>  => {
      try {
        this.loading.value = true;
        const result = await Sh.api<SurveyApiDto>({
          method: 'POST',
          route: apiRoutes.surveyApiSave,
          data: api,
        });
        this.loading.value = false;
        return result;  
      } catch (error) {
        this.loading.value = false;
        return false;
      }
    }
    getVarsApi = (api: SurveyApiDto): SurveyApiParms => {
      const globalsVars:{var: string, description: string, value: string}[] = [];
      if(api.url.includes("{id-finhava}")) globalsVars.push({var: "id-finhava", description: "id de finhava", value : ""});
      if(api.url.includes("{cif-entidad}")) globalsVars.push({var: "cif-entidad", description: "cif de la entidad", value: ""});
      const head = Sh.getObjectFromStringKeyValue(api.headers);
      const customVars = Sh.getObjectFromStringKeyValue(api.customVars);
      const custom: {var: string, description: string, value: string }[] = [];
      for(const i of Object.keys(customVars)){
        if(api.url.includes("["+i+"]")){
          custom.push({var: i, description: customVars[i], value: "" })
        }
      }
      return {
        url: api.url,
        method: api.method,
        property: api.property,
        globals: globalsVars,
        custom: custom, 
        header: Object.keys(head).map(k => ({var: k, value: head[k] }))
      }
    }
    getDataApi = async (surveyApiparams: SurveyApiParms, api?: SurveyApiDto ): Promise<ResultTest>  => {
      const options: ApiInterface = {
        method: api ? api.method :  surveyApiparams.method ,
        route:  api ? api.url : surveyApiparams.url, 
        external: true, 
        auth: false,
      };
      if(Object.keys(surveyApiparams.header).length!=0){
        const head: {}= {};
        for(const h of surveyApiparams.header){
          head[h.var] = h.value;
        }
        options.headers = head;
      }
      if(surveyApiparams.globals.length){
        for(const g of surveyApiparams.globals){
          options.route = options.route.replace(`{${g.var}}`, g.value);
        }
      }
      if(surveyApiparams.custom.length){
        for(const g of surveyApiparams.custom){
          options.route = options.route.replace(`[${g.var}]`, g.value);
        }
      }
      const result:any = await Sh.api(options);
      const res = JSONPath({path:`$.${api ? api.property : surveyApiparams.property}`, json: result });
      return {
        returnText: JSON.stringify(result, null, "  "),
        value: res, 
        url: options.route
      }
    }


  //#endregion api
  



}

// Exporta la instancia de la clase

let _suveryInstance:SurveyStore = null;
//const suveryInstance = useSurvey();
const surveyInstance = (): SurveyStore => {
  if(!_suveryInstance){
    console.log("No creada aun")
    _suveryInstance = new SurveyStore();
    //_suveryInstance.init();
  }
  return _suveryInstance;
};
export default surveyInstance;
