export interface IdentityPostDto {
    id: string,
    name: string,
    surname1: string,
    surname2: string,
    vat_number: string,
    phone: string,
    address: string,
    country: string,
    city: string,
    province: string,
    postal_code: string,
    email: string
}
export interface ResponseDto {
    statusCode: number,
    message: string,
    data: any
}
export interface FileData {
    id: string,
    url: string,
}
export interface menuItem{
    title: string,
    link: string,
}
export const menus: any = {
    ROOT: [
        {
            title: "Clientes",
            link: "/List"
        },
        {
            title: "Usuarios",
            link: "/users"
        }
    ] as menuItem[],
    CLIENT: [
        {
            title: "Empresas",
            link: "/list"
        },
        {
            title: "Usuarios",
            link: "/users"
        },
        {
            title: "Formularios",
            link: "/forms"
        }
        
    ] as menuItem[],
    GROUP: [
        {
            title: "Entidades",
            link: "/list"
        },
        {
            title: "Formularios",
            link: "/forms"
        },       {
            title: "Usuarios",
            link: "/users"
        },
    ] as menuItem[],
    ENTITY: [
        {
            title: "Usuarios",
            link: "/users"
        },
        {
            title: "Formularios",
            link: "/forms/listAssigneds"
        }

    ] as menuItem[],
}
