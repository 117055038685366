<script setup lang="ts">
import { apiRoutes } from '@/core/apiRouters';
import { Sh } from '@/core/sharedService';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import useUsers from '@/core/store/user/useUsers';
import userEdit from '@/views/users/userEdit.vue';
import { useEntity } from '@/core/store/entities/useEntity';
import { ResponseString, roles, roles_descriptions } from '@/shared/globaEnums';
import { SimpleFormResponseData } from '@/core/store/forms/dtos';
import { SimpleDataDTO } from '@/core/store/user/dtos';
import DPDialog from '@/views/components/dialog.vue';
import userIcon from "../../assets/user-icon.svg";
import entitiesIcon from "../../assets/entities-icon.svg";
import BadgeComponent from "../components/badge.vue";

const route = useRoute();
const { user, userEditing, setEdit, saveUser, errors, getDataToDelete, deleteUser } = useUsers();
const { curEntity } = useEntity();
let userId = route.params.id;
const isNew = ref<boolean>(!!userId);
const isEditing = ref<boolean>(route.path.includes('edit'));
const rePassword = ref<string>("");
const dialogShow = ref<boolean>(false);
const deactivate = ref<boolean>(false);
const canDelete = ref<boolean>(false);
const isOwner = ref<boolean>(false);
const validateEmail = ref<string>("");
const selectedId = ref<string>("");
const listUsers = ref<SimpleDataDTO[]>([]);


const entitiesToSelect = ref<{ clients: any[], groups: any[], entities: any[] }>({ clients: [], groups: [], entities: [] })
const load = async () => {
  await setEdit(<string>userId)

  if (user.value.role !== roles.ENTITY) entitiesToSelect.value.clients = await Sh.getEntitiesToSelect(null);

  if (!userEditing.value.id.trim().length) {
    isEditing.value = true;
  }
  if (user.value.role !== roles.ENTITY) {
    loadCmbs()
  }
  watch(() => [userEditing.value.clientId, userEditing.value.groupId, userEditing.value.entityId], () => {

    checkTypeUser();

  })
}

const loadCmbs = async () => {
  changeClient();
  changeGroup();
  checkTypeUser()
}

const changeClient = async () => {
  if (Sh.isNullOrEmptyOrZero(userEditing.value.clientId)) {
    entitiesToSelect.value.groups = [];
    entitiesToSelect.value.entities = [];
    userEditing.value.groupId = null;
    userEditing.value.entityId = null;
    return;
  }
  entitiesToSelect.value.groups = await Sh.getEntitiesToSelect(userEditing.value.clientId);
}
const changeGroup = async () => {
  if (Sh.isNullOrEmptyOrZero(userEditing.value.groupId)) {
    entitiesToSelect.value.entities = [];
    userEditing.value.entityId = null;
    return;
  }
  entitiesToSelect.value.entities = await Sh.getEntitiesToSelect(userEditing.value.groupId);
}

const setEditing = async () => {
  if (!isEditing.value) {
    isEditing.value = true;
    return;
  }
  checkTypeUser();
  await saveUser(rePassword.value)
  if (errors.value.length) return;

  Sh.navigate("/users");
  isEditing.value = false;
}
const checkTypeUser = () => {
  if (!Sh.isNullOrZeroString(userEditing.value.entityId)) {

    if (userEditing.value.role !== roles.ENTITY) userEditing.value.role = roles.ENTITY;
    return;
  }
  if (!Sh.isNullOrZeroString(userEditing.value.groupId)) {
    if (userEditing.value.role !== roles.GROUP) userEditing.value.role = roles.GROUP;
    return;
  }
  if (!Sh.isNullOrZeroString(userEditing.value.clientId)) {
    if (userEditing.value.role !== roles.CLIENT) userEditing.value.role = roles.CLIENT;
    return;
  }
  if (Sh.isNullOrZeroString(userEditing.value.entityId) && Sh.isNullOrZeroString(userEditing.value.groupId) && Sh.isNullOrZeroString(userEditing.value.clientId) && userEditing.value.role !== roles.ROOT) {
    userEditing.value.role = roles.ROOT;
    return;
  }
}

// Proceso de eliminación -------------------
const del = async () => {
  dialogShow.value = true;
  deactivate.value = false;
  canDelete.value = false
  validateEmail.value = "";
  selectedId.value = "";
  listUsers.value = [];
  const { forms, users, isOwner: onwner }: { forms: SimpleFormResponseData[], users: SimpleDataDTO[], isOwner: boolean } = await getDataToDelete(userId.toString());
  listUsers.value = users;
  isOwner.value = !!onwner;
  if (isOwner && !listUsers.value.length) {
    canDelete.value = false;
    return;
  }

  if (forms.length && forms.filter(x => x.state === "DRAFT").length && !forms.filter(x => x.state === "VALIDATED" || x.state === "CLOSED").length) {
    if (!users.length) {

      canDelete.value = false;
    } else {
      canDelete.value = true;

    }
    return;
  } else if (!users.length) {
    canDelete.value = false;
    return;
  } else if (forms.filter(x => x.state === "VALIDATED" || x.state === "CLOSED").length) {
    deactivate.value = true;
    return;

  } else if (users.length && !forms.length) {
    canDelete.value = true;
    return;
  }
}
const doDelUser = async () => {

  dialogShow.value = false;
  const res: ResponseString = await deleteUser(userEditing.value.id, deactivate.value ? "DEACTIVATE" : 'DELETE', selectedId.value);
  if (Sh.hasResponseError(res)) return;
  Sh.navigate("/users");

}
// Fin Proceso de eliminación -------------------

load()
</script>


<template v-if="userEdit">
  <!--Eliminar usuario-->
  <!-- <DPDialog :on-close="() => { dialogShow = false }" :show="dialogShow">
    <div>
      <h6>Eliminar usuario</h6>
      <hr>
      <p v-if="!canDelete && !deactivate && !listUsers.length">No existen mas usuarios en esta unidad organizativa y
        tiene formularios , NO puede eliminar al usuario, cree otro usuario primero o modifique los datos de éste</p>
      <div v-if="listUsers.length && !deactivate && isOwner">
        <p>Seleccione un usuario al que traspasar los formularios del usuario que intenta borrar</p>
        <select v-model="selectedId">
          <option value="">Seleccione un usuario</option>
          <option v-for="(item, idx) in listUsers" :key="idx" :value="item.id">{{ item.name }}</option>
        </select>
      </div>
      <div v-if="deactivate">
        <p>El usuario será desactivado, ya que tiene formularios asociados y terminados y no puede borrarse</p>
      </div>
      <div v-if="canDelete">
        <p>El usuario se <span style="color: red">eliminará completamente</span> de la aplicación.</p>
      </div>
      <div v-if="deactivate || listUsers.length || canDelete">
        <p>Escriba en el siguiente cuadro el correo electrónico del usuario a desactivar/eliminar <strong>{{
          userEditing.email }}</strong></p>
        <input type="text" v-model="validateEmail">
        <button class="btn btn-danger" @click="doDelUser()"
          v-bind:disabled="((listUsers.length && isOwner) ? (!selectedId.trim().length || userEditing.email !== validateEmail) : userEditing.email !== validateEmail)">{{ canDelete
            ? 'Eliminar' : 'Desactivar'}}</button>
      </div>
    </div>
  </DPDialog> -->
  <DPDialog :on-close="() => { dialogShow = false }" :show="dialogShow">
    <div class="dp-dialog-container">
      <div class="dp-dialog-header">
        <h6>Eliminar usuario</h6>
        <hr>
      </div>
      <div class="dp-dialog-body">
        <p v-if="!canDelete && !deactivate && !listUsers.length">
          No existen más usuarios en esta unidad organizativa y tiene formularios, NO puede eliminar al usuario, cree
          otro usuario primero o modifique los datos de éste.
        </p>
        <div v-if="listUsers.length && !deactivate && isOwner">
          <p>Seleccione un usuario al que traspasar los formularios del usuario que intenta borrar:</p>
          <select v-model="selectedId" class="dp-dialog-select">
            <option value="">Seleccione un usuario</option>
            <option v-for="(item, idx) in listUsers" :key="idx" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div v-if="deactivate">
          <p>El usuario será desactivado, ya que tiene formularios asociados y terminados y no puede borrarse.</p>
        </div>
        <div v-if="canDelete">
          <p>El usuario se <span class="dp-dialog-warning">eliminará completamente</span> de la aplicación.</p>
        </div>
        <div v-if="deactivate || listUsers.length || canDelete">
          <p>Escriba en el siguiente cuadro el correo electrónico del usuario a desactivar/eliminar <strong>{{
              userEditing.email }}</strong>:</p>
          <input type="text" v-model="validateEmail" class="dp-dialog-input">
          <button class="dp-dialog-btn dp-dialog-btn-danger" @click="doDelUser()"
            v-bind:disabled="((listUsers.length && isOwner) ? (!selectedId.trim().length || userEditing.email !== validateEmail) : userEditing.email !== validateEmail)">
            {{ canDelete ? 'Eliminar' : 'Desactivar' }}
          </button>
        </div>
      </div>
    </div>
  </DPDialog>

  <!--FIN Eliminar usuario-->
  <div class="container-client" v-if="userEditing">
    <div class="header-container">
      <div>
        <h2 class="H2title">Tipo de usuario: {{ roles_descriptions[userEditing.role] }}</h2>
      </div>
      <div class="BTNContainer">
        <span v-if="userEditing.status === 'INACTIVE'" class="edit-button" style="color: red"> USUARIO
          DESACTIVADO</span>
        <button v-if="userEditing.status !== 'INACTIVE'" :class="{ 'btn-green': !isEditing, 'btn-orange': isEditing }"
          @click="setEditing()">
          {{ isEditing ? 'Guardar' : 'Editar' }}
        </button>
        <button v-if="!isEditing" class="delete-button" @click="del()"><i class="bi bi-trash3 trashIcon"></i></button>
        <a href="/users" class="close-button">
          <span class="x-mark">&#10005;</span>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="badge" v-if="user.role !== roles.ENTITY">
        <BadgeComponent :icon="entitiesIcon" label="Datos de organización" />
      </div>
      <div class="col-4" v-if="user.role === roles.ROOT">
        <label>Cliente</label>
        <select class="customSelect" v-model="userEditing.clientId" @change="changeClient" v-bind:disabled="!isEditing">
          <option value="">Role ROOT</option>
          <option v-for="(item, idx) in entitiesToSelect.clients" :key="idx"
            :selected="userEditing.clientId === item.id" :value="item.id">{{ item.name }}</option>
        </select>
      </div>
      <div class="col-4"
        v-if="!Sh.isNullOrZeroString(userEditing.clientId) && [roles.ROOT.toString(), roles.CLIENT.toString()].includes(user.role)">
        <label>Empresa</label>
        <select class="customSelect" v-model="userEditing.groupId" @change="changeGroup" v-bind:disabled="!isEditing">
          <option value="">Role Cliente</option>
          <option v-for="(item, idx) in entitiesToSelect.groups" :key="idx" :selected="userEditing.groupId === item.id"
            :value="item.id">{{ item.name }}</option>
        </select>
      </div>
      <div class="col-4"
        v-if="!Sh.isNullOrZeroString(userEditing.groupId) && [roles.ROOT.toString(), roles.CLIENT.toString(), roles.GROUP].includes(user.role)">
        <label>Entidad</label>
        <select class="customSelect" v-model="userEditing.entityId" v-bind:disabled="!isEditing">
          <option value="">Role Empresa</option>
          <option v-for="(item, idx) in entitiesToSelect.entities" :key="idx"
            :selected="userEditing.entityId === item.id" :value="item.id">{{ item.name }}</option>
        </select>
      </div>

    </div>
    <div class="row">
      <div class="badge2">
        <BadgeComponent :icon="userIcon" label="Datos del usuario principal&nbsp&nbsp" />
      </div>
      <div class="col-lg-3">
        <div class="input-container">
          <input type="text" placeholder="Nombre*" v-model="userEditing.identity.name"
            v-bind:class="{ 'edit': isEditing }" v-bind:disabled="!isEditing">
        </div>
      </div>
      <div class="col-lg-3">
        <div class="input-container">
          <input type="text" placeholder="1º Apellido" v-model="userEditing.identity.surname1"
            v-bind:class="{ 'edit': isEditing }" v-bind:disabled="!isEditing">
        </div>
      </div>
      <div class="col-lg-3">
        <div class="input-container">
          <input type="text" placeholder="2º Apellido" v-model="userEditing.identity.surname2"
            v-bind:class="{ 'edit': isEditing }" v-bind:disabled="!isEditing">
        </div>
      </div>
      <div class="col-lg-3">
        <div class="input-container">
          <input type="text" placeholder="NIF/NIE" v-model="userEditing.identity.vatNumber"
            v-bind:class="{ 'edit': isEditing }" v-bind:disabled="!isEditing">
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input-container">
          <input type="email" placeholder="Correo electrónico*" v-model="userEditing.email"
            v-bind:class="{ 'edit': isEditing, 'edit error': errors.includes('user_email') }"
            v-bind:disabled="!isEditing">
          <span v-if="errors.includes('user_email_found')" class="errorMessage">Email existente </span>
          <span v-if="errors.includes('user_email_empty')" class="errorMessage">Email obligatorio </span>
          <span v-if="errors.includes('user_email_invalid')" class="errorMessage">Email incorrecto </span>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input-container">
          <input id="asdf" placeholder="Teléfono" type="tel" autocomplete="" v-model="userEditing.identity.phone"
            v-bind:class="{ 'edit': isEditing }" v-bind:disabled="!isEditing">
          <span v-if="errors.includes('identity_phone_invalid')" class="errorMessage">Teléfono incorrecto</span>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input-container">
          <input type="text" placeholder="Dirección" v-model="userEditing.identity.address"
            v-bind:class="{ 'edit': isEditing }" v-bind:disabled="!isEditing">
        </div>

      </div>
      <div class="col-lg-4">
        <div class="input-container">
          <input type="password" placeholder="Contraseña" autocomplete="new-password" v-model="userEditing.password"
            v-bind:class="{ 'edit': isEditing, 'edit error': errors.includes('user_password') }"
            v-bind:disabled="!isEditing">
          <span v-if="errors.includes('user_password')" class="errorMessage">Password obligatorio</span>
        </div>
      </div>
      <div class="col-lg-4" v-if="userEditing && userEditing.password && userEditing.password.trim().length">
        <div class="input-container">
          <input type="password" placeholder="Repetir contraseña" autocomplete="new-password" v-model="rePassword"
            v-bind:class="{ 'edit': isEditing, 'edit error': errors.includes('user_password') }"
            v-bind:disabled="!isEditing">
          <span v-if="errors.includes('user_passwordRepassword')" class="errorMessage">Password obligatorio</span>
        </div>
      </div>
    </div>
  </div>


</template>
<style scoped>
.container-client {
  padding: 50px;
  border-radius: 8px;
  max-width: 80%;
  width: 100%;
  margin: 0px auto;
  box-shadow: 0px 0px 15px #0000002e;
  margin-bottom: 50px
}

.H2title {
  color: var(--primary-color) !important;
}

label{
  color: var(--darkgreen-text);
  font-size: 16px;
  padding-left: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}

.input-container input,
.input-container textarea {
  background-color: var(--lightBlue-bg) !important;
  border: 0px solid transparent !important;
  height: 42px;
  border-radius: 21px;
  margin-bottom: 20px;
  padding-left: 25px;
}

.input-container textarea {
  resize: none;
  padding-top: 10px;
}

.input-container input::placeholder,
.edit::placeholder {
  color: var(--darkgreen-text);
  font-size: 16px;
  padding-left: 0px;
  font-weight: 600;
}

.input-container textarea::placeholder {
  color: var(--darkgreen-text);
  font-size: 16px;
  /* padding-left: 24px; */
  /* padding-top: 10px; */
  font-weight: 600;
}

.container-client {
  padding: 50px;
  border-radius: 8px;
  max-width: 80%;
  width: 100%;
  margin: 0px auto;
  box-shadow: 0px 0px 15px #0000002e;
  margin-bottom: 50px
}

input.edit {
  border: 1px solid #81B4AB;
  padding-left: 25px;
}

textarea.edit {
  padding-left: 25px;
  padding-top: 10px;
}

/* .header-container-edit, */
.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.header-container-edit input {
  width: 300px !important;
}

.addressInput {
  height: 104px !important;
}

.BTNContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.mainUser {
  margin-top: -20px !important;
}

.badge {
  margin-bottom: 5px;
}

.badge2 {
  margin-top: 30px;
  margin-bottom: 15px;
}

.error {
  border: 1px solid red;
}

.finhavaData {
  display: block;
}

.finhavaData input,
label {
  display: inline-block;
  margin-right: 10px;
  width: auto;
}

.errorMessage {
  font-size: 12px;
  color: red;
  display: block;
  margin: 0px;
  padding: 0px;
  position: relative;
  top: -20px;
}

.dp-dialog-container {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 100%;
}

.dp-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dp-dialog-header h6 {
  margin: 0;
  font-size: 1.1em;
  font-weight: bold;
  color: #333;
}

.dp-dialog-body {
  margin-top: 10px;
}

.dp-dialog-body p {
  margin-bottom: 15px;
  font-size: 0.9em;
  color: #555;
}

.dp-dialog-select {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.dp-dialog-warning {
  color: red;
  font-weight: bold;
}

.dp-dialog-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.dp-dialog-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.dp-dialog-btn-danger {
  background-color: red;
  color: white;
}

.dp-dialog-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

</style>
<script lang="ts">
export default {
  name: "userEdit"
}
</script>