<script setup lang="ts">
  import { apiRoutes } from '@/core/apiRouters';
  import { Sh } from '@/core/sharedService';
  import { computed, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useEntity } from '../../core/store/entities/useEntity';
  import { Entity, EntityListItemDto, FinhavaEntity, FinhavaEntityChecked} from '../../core/store/entities/dtos';
  import DataTableDP, { DataTableColumn } from '../components/dataTableDP.vue';
  import ModalInnerPage from '../components/modalInnerPage.vue';
  import { entityTypes, ResponseString } from '@/shared/globaEnums';
  import { SimpleDataDTO } from '@/core/store/user/dtos';
  import { SimpleFormResponseData } from '@/core/store/forms/dtos';
  import DPDialog from '@/views/components/dialog.vue';
  import userIcon from "../../assets/user-icon.svg";
  import entitiesIcon from "../../assets/entities-icon.svg";
  import BadgeComponent from "../components/badge.vue";
  import { routesPoperties, type PropsRoute } from "@/shared/globaEnums";
  import useUsers from "@/core/store/user/useUsers";

    const props = defineProps({
      entityId: {
        type: String as () => string,
        required: false
      }, 
      edit : {
        type: Boolean as () => boolean,
        required: false
      },
      loadByOther: {
        type: Boolean as () => boolean,
        required: false
      }

    })

    //#region Variables
    const emit = defineEmits(["saved"]);
    const route = useRoute();
    const {selectedEntity, childEntities, loadEntity, loadNewEntity, saveSelectedEntity, isEditing,deleteEntity, setEditing, loadEntities, checkName, getDataToDelete, checkFinhavaId, oldFinhavaId} = useEntity();
    let errors= ref<string[]>([]);
    const imageVersion = ref<number>(1);
    const entityId = props.entityId ? props.entityId: route.params.id;
    const isEdit = props.entityId ? false :  (route.params.edit  ? true : false);
    const fileInput = ref<HTMLInputElement | null>(null);
    const childLevel1 = ref<EntityListItemDto[]>([])
    const childLevel2 = ref<EntityListItemDto[]>([])
    const showChildLevel1 = ref<boolean>(false)
    const showChildLevel2 = ref<boolean>(false)
    const showModal = ref<boolean>(false);
    const entityModalId = ref<string>("");
    const curEntity = ref<Entity>(selectedEntity.value);
    const rePassword = ref<string>("");    
    const isNew = entityId === "new";
    //#endregion

    const countChid = (data: EntityListItemDto, field: string) =>{
      if(!data.groupId) return curEntity.value.childs.filter(c => c.groupId == data.id).length.toString();
      return "0"
    }
    const isVisbleDependencies = (field: string, tag: any):boolean=>{
      if(!tag) return true;
      if(tag === "level2")  return false;
      return true;
    }
    const isVisbleView = (field: string, tag: any):boolean=>{
      if(!tag) return true;
      if(tag === "level2")  return false;
      return true;
    }
    const columns:DataTableColumn[]=[
    {
        name: "name",
        field: "name",
        header: "Nombre",
        orderable: true,
      },
      {
        name: "vatNumber",
        field: "vatNumber",
        header: "CIF/NIF",
        orderable: true,
      },
      {
        name: "phone",
        field: "phone",
        header: "Teléfono",
        orderable: true,
      },
      {
        name: "address",
        field: "address",
        render: (data:EntityListItemDto , field) => data.province.trim() + " " + data.city.trim() + " " + data.address,
        header: "dirección",
        orderable: true,
      },
      {
        name: "childrenCount",
        field: "childrenCount",
        render: countChid,
        header: "Dependencias",
        visible: isVisbleDependencies,
        orderable: true,
      },
      {
        name: "ver",
        render: countChid,
        header: "Ver",
        visible: isVisbleView,
        orderable: false,
        icon: `<img class="actionIcon" src="/assets/eye_action_icon.png" alt="eye_icon">`,
        action: (data, tag) => {entityModalId.value =  data.id,  showModal.value = true}
      },
    ];

    const load =async  () =>{
      if(entityId){
        if(entityId !== "new"){
          setEditing(isEdit);
          curEntity.value = await loadEntity(entityId.toString(), true, props.entityId ? true : false);
        }else{
          setEditing();
          curEntity.value = loadNewEntity();
        }
      }
      loadChild();
    }
    const loadChild = (selectedId: string = "") => {
      if(!curEntity.value.childs || !curEntity.value.childs.length){
           showChildLevel1.value = false;
           showChildLevel2.value = false;
           return;
      }
      if(selectedId){
        childLevel2.value = curEntity.value.childs.filter(c => c.groupId && c.groupId.toUpperCase() == selectedId.toUpperCase() &&  c.id.toUpperCase() != selectedId.toUpperCase());
         showChildLevel2.value = !!childLevel2.value.length
      }else{
        childLevel1.value = curEntity.value.childs.filter(c => {
          if (curEntity.value.entityType === entityTypes.CLIENT) return  !c.groupId || !c.groupId.trim().length;
          return c.groupId && c.groupId.trim().length;

        } )
        showChildLevel1.value = !!childLevel1.value.length
        showChildLevel2.value = false;
      }
    }
    const edit = async () => {
        if(isEditing){
          errors.value = await saveSelectedEntity(curEntity.value, rePassword.value);

          if(errors.value.length) return;
          setEditing(false);
          if(props.entityId) {
            emit('saved');
            return;
          }
          Sh.navigate("/list");
        }
        setEditing(!isEditing);
    }
    const selectImage = () => {
      if (fileInput.value && isEditing) {
        fileInput.value.click();
      }
    }
    const clickRow = (data: EntityListItemDto, level: number ) => {
      if(level === 1){
        loadChild(data.id);
        return;
      }
      entityModalId.value = data.id;
      showModal.value = true;
    }
    const handleFileChange = async (event: Event) => {
      const selectedFile = (event.target as HTMLInputElement).files?.[0];
      if(!selectedFile) return;

      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'];

      if (!validImageTypes.includes(selectedFile.type)) {
        errors.value.push('img_type');
        return;
      }
      
      if (selectedFile.type === 'image/gif') {
        const arrayBuffer = await selectedFile.arrayBuffer();
        const byteArray = new Uint8Array(arrayBuffer);
        if (byteArray[0] === 0x47 && byteArray[1] === 0x49 && byteArray[2] === 0x46) {
          errors.value.push('img_gif');
          return;
        }
      }

      const formData = new FormData();
        formData.append("file", selectedFile);
        if(curEntity.value.fileData && curEntity.value.fileData.id.trim().length){
          formData.append("id", curEntity.value.fileData.id);
        }
        try{
          const result = await Sh.api<{id: string, url: string, originalName: string}>({
            method: 'POST',
            route: apiRoutes.files,
            data:formData, 
            type: "multipart"
          });
          curEntity.value = {...curEntity.value, fileData: {id: result.id, url: result.url}};
          imageVersion.value++;
        }catch{
            // TODO ver que hacer.
        }
    };


    // Init;
    load();

    // Proceso de borrado
    const dialogShow = ref<boolean>(false);
    const canDelete =  ref<boolean>(false);
    const validateCode = ref<string>("");
    const selectedId = ref<string>("");
    const listUsers = ref<SimpleDataDTO[]>([]);
    const tipoEntitdad = ref<string>("");
    const finhavaEntity = ref<FinhavaEntity>(null!);
    const finhavaEntityChecked = ref<FinhavaEntityChecked>(new FinhavaEntityChecked());
    const finhavaDialogShow = ref<boolean>(false);
    const finhavaLoading = ref<boolean>(false);
    const finhavaError = ref<boolean>(false);
    const { user } = useUsers();
    const propsRoute: PropsRoute = (<any>routesPoperties)[user.value.role];

      const buttonLabel = computed(() => {
        const title = propsRoute && propsRoute.title ? propsRoute.title : '';
        const isCliente = title.toLowerCase() === 'cliente';
        const article = isCliente ? 'Nuevo' : 'Nueva';
        const routeImg = isCliente ? '/assets/cliente_noImage.png' : '/assets/logoentity.png';

        return {
          label: `${article} ${title}`,
          routeImg: routeImg
        };
      });

      const label = buttonLabel.value.label;
      const routeImage = computed(() => {
        const title = propsRoute && propsRoute.title ? propsRoute.title : '';
        const isCliente = title.toLowerCase() === 'cliente';
        return isCliente ? '/assets/cliente_noImage.png' : '/assets/logoentity.png';
      });
  
    const textos = {
      [entityTypes.ENTITY]: {singular: "entidad", plural: "entidades", artSing: "la entidad", artPlural: "las entidades", propoSing: "una entidad", propoPlural: "unas entidades"},
      [entityTypes.CLIENT]: {singular: "cliente", plural: "clientes", artSing: "el cliente", artPlural: "los clientes", propoSing: "un cliente", propoPlural: "unos clientes" },
      [entityTypes.GROUP]: {singular: "empresa", plural: "empresas", artSing: "la empresa", artPlural: "las empresas", propoSing: "una empresa", propoPlural: "unas empresas"},
    }
    const del = async () => {
      dialogShow.value = true;
      canDelete.value = false
      validateCode.value = "";
      selectedId.value = "";
      listUsers.value = [];
      
      tipoEntitdad.value = selectedEntity.value.entityType === entityTypes.ENTITY ? "Entidades" : (selectedEntity.value.entityType === entityTypes.CLIENT ? "Clientes" : "Empresas" );
      const {formResponses, forms, entitiLevels}: {formResponses:SimpleFormResponseData[], forms: SimpleDataDTO[], entities: SimpleDataDTO[], entitiLevels: SimpleDataDTO[]} =  await getDataToDelete(entityId.toString());
      listUsers.value = entitiLevels;
      if( !formResponses.length){
          canDelete.value = true;
          return;
      }

      if(selectedEntity.value.entityType === entityTypes.ENTITY && formResponses.filter(x => x.state ==="VALIDATED" || x.state ==="CLOSED").length){
        canDelete.value = false;
        return;    
      }
      
      
      if(!entitiLevels.length && forms.length) {
        canDelete.value = false;
        return;
      }
      
      canDelete.value = true;
     }
    const doDelEntity = async () => {
      
      dialogShow.value = false;
      const res:ResponseString = await deleteEntity(selectedEntity.value.id,  selectedId.value);
      if(Sh.hasResponseError(res)) return;
      Sh.navigate("/users");

    }
    const checkFinhava = async () => {
      
      if(oldFinhavaId.value!==selectedEntity.value.finhavaId &&  selectedEntity.value.finhavaId.trim().length){
        finhavaDialogShow.value = true;
        finhavaLoading.value = true;
        finhavaError.value = false;
        finhavaEntity.value = null;
        finhavaEntityChecked.value = new FinhavaEntityChecked();
        try{
          finhavaEntity.value = await checkFinhavaId(selectedEntity.value.finhavaId);
          if(!finhavaEntity){
            throw "";
          }
          finhavaLoading.value = false;
          if(!finhavaEntity.value.Name || !finhavaEntity.value.Name.trim().length )  finhavaEntityChecked.value.Name = false;
          if(!finhavaEntity.value.Address || !finhavaEntity.value.Address.trim().length )  finhavaEntityChecked.value.Address = false;
          if(!finhavaEntity.value.Email || !finhavaEntity.value.Email.trim().length )  finhavaEntityChecked.value.Email = false;
          if(!finhavaEntity.value.PhoneNumber || !finhavaEntity.value.PhoneNumber.trim().length )  finhavaEntityChecked.value.PhoneNumber = false;
          if(!finhavaEntity.value.Vat || !finhavaEntity.value.Vat.trim().length )  finhavaEntityChecked.value.Vat = false;
        }catch{
          finhavaLoading.value = false;
          finhavaEntity.value = null;
          finhavaError.value = true;
          console.log("Value")
        }
        

      }
      
      //console.log(selectedEntity.value.finhavaId)
    }
    const importFihnavaData = () =>{
      curEntity.value.name = finhavaEntity.value.Name;
      curEntity.value.identity.address = finhavaEntity.value.Address;
      curEntity.value.identity.email = finhavaEntity.value.Email;
      curEntity.value.identity.phone = finhavaEntity.value.PhoneNumber;
      curEntity.value.identity.vatNumber = finhavaEntity.value.Vat;
      finhavaDialogShow.value = false;
    }
    const cancelImportFinhava = () =>{
      finhavaDialogShow.value = false;
    }
    

// TODO Terminar dialogo de finhava.
</script>


<template>

  <DPDialog :on-close="() => { finhavaDialogShow = false }" :show="finhavaDialogShow">
    <div class="dp-dialog-container">
      <div class="dp-dialog-header">
        <h6 v-if="finhavaLoading">Buscando código Finhava...</h6>
        <h6 v-if="finhavaEntity">Importar datos de Finhava</h6>
        <h6 v-if="finhavaError">Finhava NO encontrado</h6>
        <hr>
      </div>
      <div class="dp-dialog-body">
        <div v-if="finhavaEntity">
          <p>Seleccione los campos a importar:</p>
          <div class="finhavaData" v-if="!(!finhavaEntity.Name || !finhavaEntity.Name.trim().length)">
            <input type="checkbox" name="finhavaName" v-model="finhavaEntityChecked.Name" />
            <label for="finhavaName" class="dp-dialog-label-bold">Nombre:</label>
            <label for="finhavaName">{{ finhavaEntity.Name }}</label>
          </div>
          <div class="finhavaData" v-if="!(!finhavaEntity.Address || !finhavaEntity.Address.trim().length)">
            <input type="checkbox" id="finhavaAddress" v-model="finhavaEntityChecked.Address" />
            <label for="finhavaAddress" class="dp-dialog-label-bold">Dirección:</label>
            <label for="finhavaAddress">{{ finhavaEntity.Address }}</label>
          </div>
          <div class="finhavaData" v-if="!(!finhavaEntity.Email || !finhavaEntity.Email.trim().length)">
            <input type="checkbox" id="finhavaEmail" v-model="finhavaEntityChecked.Email" />
            <label for="finhavaEmail" class="dp-dialog-label-bold">E-mail:</label>
            <label for="finhavaEmail">{{ finhavaEntity.Email }}</label>
          </div>
          <div class="finhavaData" v-if="!(!finhavaEntity.PhoneNumber || !finhavaEntity.PhoneNumber.trim().length)">
            <input type="checkbox" id="finhavaPhone" v-model="finhavaEntityChecked.PhoneNumber" />
            <label for="finhavaPhone" class="dp-dialog-label-bold">Teléfono:</label>
            <label for="finhavaPhone">{{ finhavaEntity.PhoneNumber }}</label>
          </div>
          <div class="finhavaData" v-if="!(!finhavaEntity.Vat || !finhavaEntity.Vat.trim().length)">
            <input type="checkbox" id="finhavaVat" v-model="finhavaEntityChecked.Vat" />
            <label for="finhavaVat" class="dp-dialog-label-bold">CIF/NIF:</label>
            <label for="finhavaVat">{{ finhavaEntity.Vat }}</label>
          </div>
        </div>
        <button class="dp-dialog-btn dp-dialog-btn-primary" v-if="finhavaEntity" @click="importFihnavaData">Importar datos</button>
      </div>
    </div>
  </DPDialog>

  <DPDialog :on-close="() => { dialogShow = false }" :show="dialogShow">
    <div class="dp-dialog-container">
      <div class="dp-dialog-header">
        <h6>Eliminar usuario</h6>
        <hr>
      </div>
      <div class="dp-dialog-body">
        <p v-if="canDelete && !listUsers.length">
          No existen más {{ textos[selectedEntity.entityType].plural }} en esta unidad organizativa, NO puede eliminar a {{ textos[selectedEntity.entityType].artSing }}, cree otr{{ selectedEntity.entityType === entityTypes.CLIENT ? "o" : "a" }} {{ textos[selectedEntity.entityType].singular }} primero o modifique los datos actuales.
        </p>
        <div v-if="listUsers.length && canDelete">
          <p>Seleccione {{ textos[selectedEntity.entityType].propoSing }} al que traspasar los formularios de {{ textos[selectedEntity.entityType].artSing }} que intenta borrar:</p>
          <select v-model="selectedId" class="dp-dialog-select">
            <option value="">Seleccione {{ textos[selectedEntity.entityType].propoSing }}</option>
            <option v-for="(item, idx) in listUsers" :key="idx" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div v-if="canDelete">
          <p>{{ textos[selectedEntity.entityType].artSing }} se <span class="dp-dialog-warning">eliminará completamente</span> de la aplicación.</p>
        </div>
        <div v-if="canDelete">
          <p>Escriba o copie y pegue el siguiente código para eliminar <strong>{{ selectedEntity.id }}</strong>:</p>
          <input type="text" v-model="validateCode" class="dp-dialog-input">
          <button class="dp-dialog-btn dp-dialog-btn-danger" @click="doDelEntity()" v-bind:disabled="(listUsers.length ? (!selectedId.trim().length || selectedEntity.id !== validateCode) : selectedEntity.id !== validateCode)">
            {{ canDelete ? 'Eliminar' : 'Desactivar' }}
          </button>
        </div>
      </div>
    </div>
  </DPDialog>



  <div class="container-client">
    <div v-if="!isEditing" class="header-container">
      <div>
        <h2 class="H2title" v-if="!isEditing">{{ curEntity.name }}</h2>
        <input type="text" v-model="curEntity.name"
          v-bind:class="{'edit': isEditing, 'edit error': errors.includes('entity_name')}" v-bind:hidden="!isEditing">
      </div>
      <div class="BTNContainer">
        <button v-if="!props.entityId" class="btn-green" @click="setEditing()">Editar</button>
        <button v-if="!isEditing" class="delete-button" @click="del()"><i class="bi bi-trash3 trashIcon"></i></button>
        <a href="/list" class="close-button">
          <span class="x-mark">&#10005;</span>
        </a>
      </div>
      <!-- <button v-if="props.entityId" class="edit-button" @click="()=> emit('saved')">Cerrar</button> -->
    </div>

    <div v-if="isEditing" class="header-container-edit">
      <div class="input-container row mt-0">
        <div class="col-lg-12 pt-0">
          <h2 class="H2title2">{{label}}</h2>
          <input type="text" placeholder="Nombre*" v-model="curEntity.name"
            v-bind:class="{'edit editName': isEditing, 'edit error': errors.includes('entity_name')}" />
          <span v-if="errors.includes('entity_name')" class="errorMessage">Nombre de entidad incorrecto o
            existente</span>
        </div>
      </div>
      <div class="BTNContainer">
        <button class="btn-orange" @click="edit">Guardar</button>
        <a href="/list" class="close-button">
          <span class="x-mark">&#10005;</span>
        </a>
      </div>
    </div>
    <!-- <hr> -->
    <div class="row mt-4">
      <div class="col-lg-1 imgClient">
        <img
          :src="curEntity.fileData.url.trim().length ? `${curEntity.fileData.url}?v=${imageVersion.toString()}` : routeImage"
          @click="() => isEditing ? selectImage() : null" v-bind:class="{'cursor': isEditing}">
        <input ref="fileInput" type="file" style="display: none" @change="handleFileChange" />
        <div v-if="errors.length" class="error-container">
        </div>
      </div>
      <div class="col-lg-5">
        <div class="input-container">
          <input type="text" placeholder="Finhava ID*" v-model="curEntity.finhavaId" v-bind:class="{'edit': isEditing}"
            v-bind:disabled="!isEditing" @blur="checkFinhava">
        </div>
        <div class="input-container">
          <textarea placeholder="Dirección" class="addressInput" v-model="curEntity.identity.address"
            v-bind:class="{'edit': isEditing}" v-bind:disabled="!isEditing"></textarea>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="input-container">
          <input type="text" placeholder="NIF/CIF*" v-model="curEntity.identity.vatNumber"
            v-bind:class="{'edit': isEditing}" v-bind:disabled="!isEditing">
        </div>
        <div class="input-container">
          <input type="email" placeholder="Correo electrónico*" v-model="curEntity.identity.email"
            v-bind:class="{'edit': isEditing, 'edit error': errors.includes('entity_email')}"
            v-bind:disabled="!isEditing">

          <span v-if="errors.includes('entity_email')" class="errorMessage">Email obligatorio </span>
          <span v-if="errors.includes('entity_email_invalid')" class="errorMessage">Email incorrecto </span>

        </div>
        <div class="input-container">
          <input type="tel" placeholder="Teléfono*" v-model="curEntity.identity.phone" class="phoneInput"
            v-bind:class="{'edit': isEditing, 'edit error': errors.includes('identity_phone')}"
            v-bind:disabled="!isEditing">
          <span v-if="errors.includes('identity_phone')" class="errorMessage">Teléfono obligatorio</span>
          <span v-if="errors.includes('identity_phone_invalid')" class="errorMessage">Teléfono incorrecto</span>

        </div>
      </div>
    </div>
    <div>
      <span v-if="errors.includes('img_type')" class="errorMessage">Tipo de archivo no permitido. Solo se permiten imágenes JPEG, PNG y WEBP. </span>
      <span v-if="errors.includes('img_gif')" class="errorMessage">No se permiten GIFs animados. </span>
    </div>
    <div class="row mt-4 mainUser">
      <div class="badge">
        <BadgeComponent :icon="userIcon" label="Datos del usuario principal&nbsp&nbsp" />
      </div>
      <!-- <hr> -->
      <div class="col-lg-3">
        <div class="input-container">
          <input type="text" placeholder="Nombre" v-model="curEntity.user.identity.name"
            v-bind:class="{'edit': isEditing}" v-bind:disabled="!isEditing">
        </div>
      </div>
      <div class="col-lg-3">
        <div class="input-container">
          <input type="text" placeholder="1º Apellido" v-model="curEntity.user.identity.surname1"
            v-bind:class="{'edit': isEditing}" v-bind:disabled="!isEditing">
        </div>
      </div>
      <div class="col-lg-3">
        <div class="input-container">
          <input type="text" placeholder="2º Apellido" v-model="curEntity.user.identity.surname2"
            v-bind:class="{'edit': isEditing}" v-bind:disabled="!isEditing">
        </div>
      </div>
      <div class="col-lg-3">
        <div class="input-container">
          <input type="text" placeholder="NIF/NIE" v-model="curEntity.user.identity.vatNumber"
            v-bind:class="{'edit': isEditing}" v-bind:disabled="!isEditing">
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input-container">
          <input type="email" placeholder="Correo electrónico*" v-model="curEntity.user.email"
            v-bind:class="{'edit': isEditing, 'edit error': errors.includes('user_email')}"
            v-bind:disabled="!isEditing">
          <span v-if="errors.includes('user_email_found')" class="errorMessage">Email existente </span>
          <span v-if="errors.includes('user_email_empty')" class="errorMessage">Email obligatorio </span>
          <span v-if="errors.includes('user_email_invalid')" class="errorMessage">Email incorrecto </span>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input-container">
          <input id="asdf" type="tel" placeholder="Teléfono" autocomplete="" v-model="curEntity.user.identity.phone"
            v-bind:class="{'edit': isEditing}" v-bind:disabled="!isEditing">
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input-container">
          <input type="text" placeholder="Dirección" v-model="curEntity.user.identity.address"
            v-bind:class="{'edit': isEditing}" v-bind:disabled="!isEditing">
        </div>

      </div>
      <div class="col-lg-4">
        <div class="input-container">
          <input type="password" placeholder="Contraseña*" autocomplete="new-password" v-model="curEntity.user.password"
            v-bind:class="{'edit': isEditing, 'edit error': errors.includes('user_password')}"
            v-bind:disabled="!isEditing">
          <span v-if="errors.includes('user_password')" class="errorMessage">Password obligatorio</span>
        </div>
      </div>
      <div class="col-lg-4" v-if="curEntity.user.password && curEntity.user.password.trim().length">
        <div class="input-container">
          <input type="password" placeholder="Repetir contraseña*" autocomplete="new-password" v-model="rePassword"
            v-bind:class="{'edit': isEditing, 'edit error': errors.includes('user_password')}"
            v-bind:disabled="!isEditing">
          <span v-if="errors.includes('user_passwordRepassword')" class="errorMessage">Password obligatorio</span>
        </div>
      </div>
    </div>
    <!-- <div v-if="isEditing" class="row">
        
        <div class="col-1 offset-11" v-if="isEditing">
          <button class="btn btn-danger small" @click="del()" style="padding: 0px; margin: 0px;" ><i class="bi bi-trash3" style="color: white; margin: 0px"></i></button>
        </div>
        

      </div> -->
    <div class="table-responsive">
      <template v-if="showChildLevel1">
        <div class="badge2">
          <BadgeComponent :icon="entitiesIcon" :label="entityTypes.CLIENT ? 'Empresas' : 'Entidades'" />
        </div>
        <DataTableDP :columns="columns" :data="childLevel1" :rowClick="(data) => clickRow(data, 1)" />
      </template>
      <template v-if="showChildLevel2">
        <DataTableDP :columns="columns" :data="childLevel2" :tag="'level2'" :rowClick="(data) => clickRow(data, 2)" />
      </template>


    </div>

    <!-- </form> -->
    <!-- <hr> -->
  </div>
  <ModalInnerPage v-if="showModal">
    <entityBase :entityId="entityModalId" :edit="false" @saved="showModal = false" />
  </ModalInnerPage>
</template>

<style scoped>

.imgClient{
  margin-right: 80px;
  cursor: pointer;
}

.imgClient img {
  max-width: 166px; 
  max-height: 166px;
  width: auto;
  height: auto;
  object-fit: cover;

}

.input-container input, .input-container textarea{
  background-color: var(--lightBlue-bg) !important;
  border: 0px solid transparent !important;
  height: 42px;
  border-radius: 21px;
  margin-bottom: 20px;
  padding-left: 25px;
}

.input-container textarea{
  resize: none;
  padding-top: 10px;
}

.input-container input::placeholder, .edit::placeholder {
  color: var(--darkgreen-text);
  font-size: 16px;
  padding-left: 0px;
  font-weight: 600;
}

.input-container textarea::placeholder{
  color: var(--darkgreen-text);
  font-size: 16px;
  /* padding-left: 24px; */
  /* padding-top: 10px; */
  font-weight: 600;
}

.container-client {
  padding: 50px;
  border-radius: 8px;
  max-width: 80%;
  width: 100%;
  margin: 0px auto;
  box-shadow: 0px 0px 15px #0000002e;
  margin-bottom: 50px

}

input.edit {
    border:  1px solid #81B4AB;
    padding-left: 25px;
}

textarea.edit {
  padding-left: 25px;
  padding-top: 10px;
}

.header-container-edit, .header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.header-container-edit input{
  width: 300px !important;
}

.addressInput {
  height: 104px !important;
}

.BTNContainer{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.mainUser {
  margin-top: -20px !important;
}

.badge {
  margin-bottom: 10px;
}

.badge2 {
  margin-top: 20px;
  margin-bottom: -20px;
}

.H2title{
  color: var(--primary-color) !important;
}

.H2title2{
  color: var(--primary-color) !important;
  margin-bottom: 20px;
}

.error{
  border: 1px solid red;
}

.finhavaData{
  display: block;
}
.finhavaData input, label{
  display: inline-block;
  margin-right: 10px;
  width: auto;
}
.errorMessage{
  font-size: 12px;
    color: red;
    display: block;
    margin: 0px;
    padding: 0px;
    position: relative;
    top: -20px;
}

.dp-dialog-container {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 100%;
}

.dp-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dp-dialog-header h6 {
  margin: 0;
  font-size: 1.1em;
  font-weight: bold;
  color: #333;
}

.dp-dialog-body {
  margin-top: 10px;
}

.dp-dialog-body p {
  margin-bottom: 15px;
  font-size: 0.9em;
  color: #555;
}

.finhavaData {
  margin-bottom: 10px;
}

.dp-dialog-label-bold {
  font-weight: bold;
}

.dp-dialog-select {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.dp-dialog-warning {
  color: red;
  font-weight: bold;
}

.dp-dialog-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.dp-dialog-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.dp-dialog-btn-primary {
  background-color: blue;
  color: white;
}

.dp-dialog-btn-danger {
  background-color: red;
  color: white;
}

.dp-dialog-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message{
  width: fit-content;
  color: red;
  font-size: 16px;
  margin: 0px 0 0 20px;
  padding: 5px;
  background-color: var(--secondary-color);
  border-radius: 10px;
  opacity: 50%;
}
</style>