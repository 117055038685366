<script setup lang="ts">
import Survey from '@/core/store/survey/surveyStore';
import DPDialog from '@/views/components/dialog.vue';
import { computed, ref, watch } from 'vue';
const { originSelectorShow, originSelectorItem, getForms, getItems, currentDesign, asignItemNew } = Survey();
const selectedFormId = ref<string>("");
const selectedItemId = ref<string>("");
const formList = ref<{ id: string, name: string }[]>([]);
const formListItems = ref<{ id: string, name: string }[]>([]);
const selectedFormName = computed(() => {
  if (selectedFormId.value) {
    return formList.value.find(f => f.id == selectedFormId.value)?.name;
  }
  return "";
})
watch(originSelectorShow, () => {
  if (originSelectorShow.value) {
    if (!formList.value.length) {
      getForms().then((values) => {
        formList.value = values.filter(f => f.id !== currentDesign.value.cab.id);
      })// cargar lista de formularios
    }

  }
})
watch(selectedFormId, (newValue, oldVlue) => {
  if (newValue != oldVlue) {
    getItems(newValue).then((values) => {
      formListItems.value = values;
    })
  }
})


const closeDialog = () => {
  originSelectorShow.value = false;
  formList.value = [];
}
const asign = () => {
  if (!selectedItemId.value) return;
  asignItemNew(selectedItemId.value, originSelectorItem.value);
  closeDialog();
}
</script>

<template>

  <DPDialog :show="originSelectorShow" :closeLostFocus="true" :onClose="closeDialog">
    <div class="originSelectorContainer">
      <!-- HEADER -->
      <div class="refFormModalHeaderContainer">
        <h3 class="modalRefFormTitle">Formularios</h3>
        <button class="close-button" @click="closeDialog">
          <span class="x-mark">&#10005;</span>
        </button>
      </div>
      <!-- SELECTORES -->
      <div class="originSelector">
        <!-- FORMULARIOS -->
        <div class="originSelectorForm">
          <label class="selectorHeader">Lista de formularios</label>
          <div class="formsListContainer">
            <div v-for="f in formList" :key="f.id" v-on:click="selectedFormId = f.id" class="originSelectorFormItem"
              :class="{ 'originSelectorFormItemSelected': selectedFormId == f.id }">
              {{ f.name }}
            </div>
          </div>
        </div>
        <!-- PROPIEDADES -->
        <div class="originSelectorItems">
          <label class="selectorHeader">Propiedades</label>
          <!-- <label>Propiedades ({{selectedFormName}})</label> -->
          <div class="propertiesListContainer">
            <div v-for="f in formListItems" :key="f.id" v-on:click="selectedItemId = f.id" class="originSelectorFormItem"
              :class="{ 'originSelectorFormItemSelected': selectedItemId == f.id }">
              {{ f.name }}
            </div>
          </div>
        </div>
      </div>
      <button class="refFormModalAsignBTN" v-on:click="asign">
        Asignar
      </button>
    </div>
  </DPDialog>

</template>
<script lang="ts">
export default {
  name: "SuverySelectorOrigin"
}
</script>

<style scoped lang="scss">
@import url(./survey.scss);

.originSelectorContainer {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  max-width: 90vi;
  padding: 20px;
}

.refFormModalHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modalRefFormTitle {
  font-size: 30px;
  font-weight: 600;
  color: var(--primary-color);
}

.originSelector {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.selectorHeader{
  font-size: 16px;
  font-weight: 400;
  color: var(--darkgreen-text);
  margin-bottom: 10px;
}

.originSelectorForm {
  overflow-y: auto;
  text-overflow: ellipsis;
  margin-right: 4%;
  width: 46%;
}

.originSelectorItems {
  overflow-y: auto;
  text-overflow: ellipsis;
  margin-left: 4%;
  width: 46%;
}

.formsListContainer, .propertiesListContainer {
  background-color: var(--compositor-color);
  padding: 14px;
  min-height: 200px;
  max-height: 800px;
  border-radius: 17px;

}

.originSelectorFormItem {
  cursor: pointer;
  padding: 2px 10px;

}

.originSelectorFormItem:hover {
  background-color: var(--secondary-color);
  cursor: pointer;

}

.originSelectorFormItemSelected {
  background-color: var(--primary-color);
  color: white;
}
</style>